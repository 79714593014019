#site-header{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: $pc-head;
    background: #FFF;
    @include flex();
    align-items: center;
    z-index: 1000;
    transition: box-shadow 200ms;
    @media screen and (max-width:$responsive){
        height: $sp-head;   
    }
    &:hover{
        box-shadow: 0 0 32px rgba(#000,.1);
    }
    h1{
        width: 124px;
        margin: 2px 0 0 48px;
        @media screen and (max-width:$tab){
            margin-left: 20px;   
        }
        @media screen and (max-width:$responsive){
            width: 100px;
            margin-left: 15px;   
        }
        img{
            width: 100%;
        }
    }
    .navigations{
        @media screen and (min-width:$responsive + 1px){
            display: block !important;
        }
        @media screen and (max-width:$responsive){
            position: relative;
            z-index: 10;
        }
    }
       
    .close{
        display: none;
        @media screen and (max-width:$responsive){
            $size: 40px;
            width: $size;
            height: $size;
            top: 9px;
            right: 14px;
            position: absolute;
            border-radius: 50%;
            cursor: pointer;
            display: block;
            z-index: 9999;
            &.active{
                animation: shows 600ms forwards;
                @keyframes shows{
                    0%{
                        opacity: 0;
                        transform: rotate(45deg);
                    }
                    100%{
                        transform: rotate(0deg);
                        opacity: 1;
                    }
                }

            }
            &:before,
            &:after{
                content: '';
                width: 60%;
                height: 1.5px;
                background: #FFF;
                position: absolute;
                top: 50%;
                left: 20%;
                transform: rotate(45deg);
            }
            &:after{
                transform: rotate(-45deg);
            }
        }
    }

    .gnavi{
        @media screen and (max-width:$responsive){
            $size: 40px;
            width: $size;
            height: $size;
            background: $normal;
            margin: 0 15px 0 0;
            position: relative;
            border-radius: 50%;
            cursor: pointer;
            &:before,
            &:after{
                content: '';
                width: 40%;
                height: 2px;
                background: #FFF;
                position: absolute;
                top: 40%;
                left: 30%;
                z-index: 2;
            }
            &:after{
                top: auto;
                bottom: 40%;
            }
        }
        .navigations > ul{
            @include flex();
            align-items: center;
            justify-content: flex-start;
            @media screen and (max-width:$responsive){
                position: fixed;
                top: 0;
                left: 0;
                z-index: 9500;
                width: 100%;
                height: 100%;
                overflow: auto;
                background: $normal;
                color: #FFF;
                font-size: 16px;
                padding: 80px 0 0;
                display: block;
                a{
                    color: #FFF;
                }
            }
            > li{
                list-style: none;
                padding: 0 64px 0 0;
                position: relative;
                @media screen and (max-width:$tab){
                    padding: 0 48px 0 0;   
                }
                @media screen and (max-width:$responsive){
                    padding: 0 15px;
                    border-bottom: rgba(#FFF,.12) 1px solid;
                }
                &:after{
                    content: '';
                    width: 2px;
                    height: 50%;
                    background: $border;
                    position: absolute;
                    top: 25%;
                    right: 32px;
                    transform: translateX(-50%) skewX(-24deg);
                    @media screen and (max-width:$tab){
                        right: 24px;   
                    }
                    @media screen and (max-width:$responsive){
                        display: none;
                    }
                }
                &:nth-last-child(-n+2){
                    &:after{
                        display: none;
                    }
                }
                
                &.contact{
                    @media screen and (min-width:$responsive + 1){
                        padding: 0;
                        background: $normal;
                        a{
                            width: $pc-head * 1.6;
                            height: $pc-head;
                            color: #FFF;
                            @include flex();
                            align-items: center;
                            justify-content: center;
                            text-align: center;
                            font-size: 12px;
                            font-weight: 400;
                            letter-spacing: .048em;
                            @media screen and (max-width:$tab){
                                width: $pc-head * 1.2;
                            }
                            span{
                                display: block;
                                position: relative;
                                z-index: 2;
                                i{
                                    width: 100%;
                                    display: block;
                                    font-size: 18px;
                                    margin: 0 0 6px;
                                }
                            }
                            &:before{
                                display: none;
                            }
                            &:after{
                                content: '';
                                width: 100%;
                                height: 0;
                                position: absolute;
                                bottom: 0;
                                left: 0;
                                background: $green;
                                z-index: 1;
                                @include transition(200ms);
                            }
                            &:hover{
                                &:after{
                                    height: 100%;
                                }
                            }
                        }
                    }
                    @media screen and (max-width:$responsive){
                        span > i{
                            display: none;
                        }
                    }
                }
                > a{
                    color: $normal;
                    font-weight: 600;
                    position: relative;
                    > i{
                        display: none;
                    }
                    @media screen and (max-width:$responsive){
                        color: #FFF;
                        padding: 15px;
                        display: block;
                        position: relative;
                        > i{
                            display: block;
                            position: absolute;
                            top: 50%;
                            right: 15px;
                            font-size: 10px;
                            opacity: .64;
                            transform: translateY(-50%);
                        }
                    }
                    &:before{
                        content: '';
                        width: 0;
                        height: 4px;
                        background: $green;
                        position: absolute;
                        left: 50%;
                        transform: translateX(-50%);
                        bottom: -29px;
                        @include transition(160ms);
                        @media screen and (max-width:$responsive){
                            display: none;
                        }
                    }
                    &:hover{
                        &:before{
                            width: 40px;
                        }
                    }
                }
            }
            dl{
                position: relative;
                @media screen and (max-width:$responsive){
                    padding: 15px;   
                }
                dt{
                    font-weight: 600;
                    position: relative;
                    cursor: pointer;
                    > i{
                        display: none;
                        @media screen and (max-width:$responsive){
                            display: block;
                            position: absolute;
                            top: 50%;
                            right: 0;
                            font-size: 10px;
                            opacity: .64;
                            transform: translateY(-50%);
                        } 
                    }
                }
                dd{
                    position: absolute;
                    top: 52px;
                    left: -20%;
                    width: 140%;
                    display: none;
                    @media screen and (max-width:$responsive){
                        position: relative;
                        top: 0;
                        left: 0;
                        width: auto;
                        padding: 15px 0 0;
                        &.active{
                            display: block;
                        }
                    }
                    .child{
                        box-shadow: 0 8px 8px rgba(#000,.08);
                        @media screen and (max-width:$responsive){
                            box-shadow: none;
                            background: rgba(#FFF,.05);
                        }
                        li{
                            border-top: $border 1px solid;
                            @media screen and (max-width:$responsive){
                                border-top: none;
                                border-bottom: rgba(#FFF,.08) 1px solid;
                                &:last-child{
                                    border: none;
                                }
                            }
                            &:first-child{
                                border-top: none;
                            }
                            a{
                                background: #FFF;
                                display: block;
                                padding: 12px 15px;
                                color: $normal;
                                text-align: center;
                                font-weight: 600;
                                @media screen and (max-width:$responsive){
                                    background: none;
                                    text-align: left;
                                    color: #FFF;
                                    font-size: 14px;
                                }
                                i{
                                    display: inline-block;
                                    margin: 0 0 0 10px;
                                    font-size: 11px;
                                    opacity: .24;
                                }
                                &:hover{
                                    color: $green;
                                    i{
                                        opacity: 1;
                                    }
                                }
                            }
                        }
                    }
                }
                @media screen and (min-width:$responsive + 1){
                    &:hover{
                        dt{
                            &:before{
                                content: '';
                                width: 140%;
                                height: $pc-head;
                                position: absolute;
                                top: -25px;
                                left: -20%;
                            }
                        }
                        dd{
                            display: block;
                        }
                    }
                }
            }
        }
    }
}