.site-company{
    .philosophy{
        &__dl{
            text-align: center;
            font-weight: 700;
            padding: 40px 0;
            border: $glay 10px solid;
            @media screen and (max-width:$responsive){
                padding: 24px 20px;
                border-width: 8px;
            }
            dt{
                font-size: 24px;
                @media screen and (max-width:$responsive){
                    font-size: 20px;   
                }
                &:after{
                    content: '';
                    display: block;
                    width: 40px;
                    height: 2px;
                    background: $normal;
                    margin: 16px auto;
                }
            }
            dd{
                font-size: 15px;
                line-height: 1.8;
                @media screen and (max-width:$responsive){
                    font-size: 13px;
                    text-align: justify;
                    br{
                        display: none;
                    }
                }
            }
        }
        &__box{
            position: relative;
            margin: 64px 0 0;
            padding: 80px 0;
            @media screen and (max-width:$tab){
                margin: 40px 0 0;
                padding: 64px 0;
            }
            @media screen and (max-width:$responsive){
                margin: 32px 0 0;
                padding: 0;
            }
            &:before{
                content: '';
                background: $glay;
                width: 80%;
                height: 100%;
                position: absolute;
                top: 0;
                right: 0;
                @media screen and (max-width:$tab){
                    right: -15px;   
                }
                @media screen and (max-width:$responsive){
                    display: none;
                }
            }
            &__bg{
                position: absolute;
                top: 80px;
                left: 0;
                width: 50%;
                height: calc(100% - 160px);
                background-size: cover;
                background-position: center;
                @media screen and (max-width:$tab){
                    top: 64px;
                    left: -15px;
                    height: calc(100% - 128px);
                }
                @media screen and (max-width:$responsive){
                    position: relative;
                    top: 0;
                    left: -15px;
                    width: calc(100% + 30px);
                    height: 200px;
                    margin: 0 0 40px;
                }
            }
            &__txt{
                margin: 0 0 0 50%;
                padding: 80px 0;
                padding-left: 52px;
                @media screen and (max-width:$tab){
                    padding: 64px 0;
                    padding-left: 24px;
                }
                @media screen and (max-width:$responsive){
                    margin: 0;
                    padding: 0;
                }
                dt{
                    font-size: 36px;
                    font-weight: 700;
                    line-height: 1.56;
                    @media screen and (max-width:$tab){
                        font-size: 24px;   
                    }
                    @media screen and (max-width:$responsive){
                        text-align: center;
                        font-size: 20px;
                    }
                    &:before{
                        content: attr(data-eng);
                        display: block;
                        margin: 0 0 8px;
                        color: $green;
                        font-weight: 600;
                        font-size: 14px;
                        text-indent: 2px;
                        letter-spacing: .24em;
                    }
                }
                dd{
                    margin: 20px 0 0;
                    font-size: 15px;
                    text-align: justify;
                    @media screen and (max-width:$tab){
                        font-size: 14px;
                        margin-right: 15px;
                    }
                    @media screen and (max-width:$responsive){
                        margin: 15px 0 0;
                        font-size: 13.5px;
                    }
                }
            }
        }
    }
    dl.data{
        &.mt{
            margin: 20px 0 0;
        }
        dt{
            display: inline-block;
            padding: 2px 0;
            color: #FFF;
            font-size: 14px;
            background: $normal;
            width: 180px;
            text-align: center;
            @media screen and (max-width:$responsive){
                width: auto;
                display: block;
                margin: 0 0 8px;
                padding: 3px 0;
            }
        }
        dd{
            margin: 8px 0 0;
        }
    }
    .map{
        iframe{
            filter: grayscale(1);
            vertical-align: bottom;
            @media screen and (max-width:$responsive){
                height: 160px;   
            }
        }
    }
    .license{
        @include flex();
        align-items: center;
        justify-content: flex-start;
        figure{
            width: 400px;
            margin: 0 24px 0 0;
            @media screen and (max-width:$tab){
                width: 300px;   
            }
            @media screen and (max-width:$responsive){
                width: auto;
                margin: 0 0 16px;
            }
            img{
                width: 100%;
            }
        }
    }
    .aw{
        img{
            max-width: 100%;
        }
    }
	
	.agenda{
		border: $glay 16px solid;
		background: #FFF;
		padding: 20px;
		@media screen and (max-width:$responsive){
			border: none;
			padding: 0;
		}
		dl{
			@include flex();
			align-items: center;
			margin: 0 0 20px;
			padding: 0 0 20px;
			border-bottom: $border 1px solid;
			@media screen and (max-width:$responsive){
				display: block;
				margin: 0 0 15px;
				padding: 0 0 15px;
			}
			&:last-of-type{
				margin: 0;
				padding: 0;
				border: none;
			}
			dt{
				background: darken($green, 4%);
				color: #FFF;
				padding: 4px 0;
				width: 160px;
				text-align: center;
				font-weight: 700;
				@media screen and (max-width:$responsive){
					width: auto;
					margin: 0 0 8px;
				}
			}
			dd{
				width: calc(100% - 160px);
				font-size: 16px;
				padding: 0 0 0 32px;
				@media screen and (max-width:$responsive){
					width: auto;
					font-size: 14px;
					padding: 0 15px;
				}
			}
		}
	}
}