@charset "utf-8";
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');

@import "module/reset";
@import "module/module";


/* BASE COLOR
------------------------------------------------------------*/
$normal:#202020;
$link:#D02;
$border:#e8e8e8;
$light:#ddd;

$green: #00cbb6;
$glay: #f4f5f4;


/* SITE SETTING
------------------------------------------------------------*/
$width:1120px;
$pc-head:78px;
$sp-head:60px;

$tab: $width - 1px;
$responsive: 980px;

@media screen and (min-width:$responsive + 1){
	@include default(
		$width, //サイト幅
		14px, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
		#FFF //ボディ背景
	);
}

@media screen and (max-width:$tab){
	@include default(
		auto, //サイト幅
		13px, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
		#FFF //ボディ背景
	);
}
@media screen and (max-width:$responsive){
	@include default(
		auto, //サイト幅
		13px, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
		#FFF //ボディ背景
	);
}

::selection {
    background: $normal;
    color: #FFF;
}

body{
    &.windows{
        text-shadow: 0 0 rgba(#000,.56);
    }
}

@import "common/header";
@import "common/footer";
@import "common/bread";
@import "page/index";
@import "page/company";
@import "page/recruit";
@import "page/contact";
@import "page/product";
@import "page/news";

#site-wrapper,#site-footer{
    position: relative;
    top: $pc-head;
    @media screen and (max-width:$responsive){
        top: $sp-head;
    }
}

.site-clum{
    @include flex();
    @media screen and (max-width:$responsive){
        display: block;   
    }
    h2.borders,
    h3.borders,
    h4.borders{
        border-bottom: $border 1px solid;
		position: relative;
        padding: 12px;
		padding-left: 20px;
        font-size: 22px;
		line-height: 1;
        font-weight: 700;
		margin: 0 0 16px;
		@media screen and (max-width:$responsive){
			font-size: 18px;
			margin: 0 0 20px;
			padding-left: 18px;
		}
		small{
			display: inline-block;
			margin: 0 0 0 20px;
			font-size: 13px;
			@media screen and (max-width:$responsive){
				margin: 0 0 0 15px;
				font-size: 11px;
			}
		}
		&:after{
			content: '';
			width: 5px;
			height: 70%;
			background: $green;
			border-radius: 4px;
			display: block;
			position: absolute;
			top: 15%;
			left: 0;
		}
    }

    &__sidebar{
        width: 280px;
        @media screen and (max-width:$tab){
            width: 260px;   
        }
        @media screen and (max-width:$responsive){
            width: auto;
            margin: 32px 0 0;
        }
    }
    &__container{
        width: calc(100% - 280px);
        padding: 0 56px 0 0;
        @media screen and (max-width:$tab){
            width: calc(100% - 260px);
            padding: 0 40px 0 0; 
        }
        @media screen and (max-width:$responsive){
            width: auto;
            padding: 0;
        }
    }
}

.pager{
    margin: 24px 0 0;
    text-align: center;
    a,span{
        $size: 28px;
        width: $size;
        height: $size;
        line-height: $size - 2px;
        text-align: center;
        display: inline-block;
        margin: 0 3px;
        border: $border 1px solid;
        background: $border;
        color: $normal;
        font-size: 13px;
    }
    span{
        background: $green;
        border-color: $green;
        color: #FFF;
    }
    a{
        &:hover{
            color: $green;
            background: #FFF;
            border-color: $green;
        }
    }
}


.site-module{
    &.wrap{
        padding: 104px 0;
        @media screen and (max-width:$tab){
            padding: 64px 20px;   
        }
        @media screen and (max-width:$responsive){
            padding: 40px 15px;   
        }
        &.mid{
            padding: 80px 0;
            @media screen and (max-width:$tab){
                padding: 64px 20px;   
            }
            @media screen and (max-width:$responsive){
                padding: 40px 15px;   
            }
        }
        &.no-top{
            padding-top: 0;
        }
        &.no-bottom{
            padding-bottom: 0;
        }
    }
    h2.large,
    h3.large{
        text-align: center;
        color: $normal;
        font-size: 16px;
        font-weight: 700;
        line-height: 1;
        margin: 0 0 40px;
        @media screen and (max-width:$responsive){
            font-size: 14px;
            margin: 0 0 24px;
        }
        &.white{
            color: #FFF;
        }
        &:before{
            content: attr(data-eng);
            font-size: 42px;
            letter-spacing: .04em;
            display: block;
            margin: 0 0 12px;
            @media screen and (max-width:$responsive){
                font-size: 28px;   
            }
        }
    }
    .btnarea{
        margin: 48px 0 0;
        text-align: center;
        @media screen and (max-width:$responsive){
            margin: 24px 0 0;   
        }
        &.s{
            margin: 24px 0 0;
            a{
                display: block;
                padding: 8px 0;
                border-radius: 4px;
            }
        }
        a{
            border-radius: 999px;
            background: $normal;
            color: #FFF;
            border: $normal 1px solid;
            display: inline-block;
            padding: 8px 80px;
            font-size: 17px;
            font-weight: 700;
            @media screen and (max-width:$responsive){
                display: block;
                font-size: 16px;
                padding: 8px 0;
                &:last-of-type{
                    margin: 0;
                }
            }
            &:hover{
                opacity: .88;
            }
            &.arrow{
                position: relative;
                &:after{
                    content: "\f0a9";
                    font-weight: 900;
                    font-family: "Font Awesome 5 Free";
                    position: absolute;
                    top: 50%;
                    right: 16px;
                    font-size: 12px;
                    transform: translateY(-50%);
                }
            }
        }
    }
    .table-style{
        border-collapse: collapse;
        width: 100%;
        @media screen and (max-width:$responsive){
            display: block;
            border: $border 1px solid;
        }
        thead,
        tbody{
            @media screen and (max-width:$responsive){
                display: block;   
            }
            tr{
                border-bottom: $border 1px solid;
                @media screen and (max-width:$responsive){
                    display: block;
                    &:last-child{
                        border: none;
                    }
                }
                &:first-child{
                    border-top: $border 1px solid;
                    @media screen and (max-width:$responsive){
                        border: none;
                    }
                }
                th,td{
                    padding: 40px 0;
                    font-size: 15px;
                    vertical-align: middle;
                    text-align: left;
                    @media screen and (max-width:$responsive){
                        display: block;
                        font-size: 14px;
                        padding: 15px 15px;
                    }
					input[type=text],
					input[type=email],
					input[type=tel],
					select,
					option,
					textarea{
						@include font();
						border: $border 1px solid;
						padding: 8px 10px;
						width: 100%;
						font-size: 15px;
						border-radius: 4px;
						overflow: hidden;
						&:focus{
							box-shadow: inset 1px 2px 2px rgba(#000,.16);
						}
					}
                    label{
                        display: inline-block;
                        margin: 0 20px 0 0;
                        input{
                            margin: 0 8px 0 0;
                            display: inline-block;
                            vertical-align: middle;
                            transform: translateY(-1px);
                            cursor: pointer;
                        }
                        &:last-of-type{
                            margin: 0;
                        }
                    }
					select{
						width: auto;
					}
					textarea{
						height: 200px;
					}
                }
                th{
                    width: 20%;
                    font-size: 17px;
					&.hiss{
                        width: 25%;
                        padding-right: 40px;
                        @media screen and (max-width:$responsive){
                            width: auto;
                            padding: 15px;
                        }
						&:after{
							content: '必須';
							float: right;
							background: $green;
							color: #FFF;
							padding: 2px 12px;
							border-radius: 4px;
							font-weight: 700;
							font-size: 12px;
						}
					}
                    @media screen and (max-width:$responsive){
                        width: auto;
                        font-size: 15px;
                        background: $glay;
                    }
                }
            }
        }
    }
}

// USE ANIMATE
.scroll-anime{
	opacity: 0;
	transform: translateY(48px);
	@include transition(800ms);
	&._active{
		opacity: 1;
		transform: translateY(0);
	}
}

.scroll-img{
	position: relative;
	$ease-transition: cubic-bezier(0.86, 0, 0.07, 1);
	$speed: 600ms;
	&:before,
	&:after{
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		height: 100%;
        z-index: 2;
	}
	&:before{
		background: $green;
		@include transition($speed , $delay:$speed , $ease:$ease-transition);
	}
	&:after{
		background: #FFF;
		@include transition($speed , $ease: $ease-transition);
	}
	&.__active{
		&:before{
			width: 0;
		}
		&:after{
			width: 0;
		}
	}
}

.scale-enter-active, .scale-leave-active {
	transition: 600ms;
}
.scale-enter, .scale-leave-to {
	opacity: 0;
}

