.site-index{
    .firstview{
        height: 56vh;
        min-height: 560px;
        position: relative;
        @media screen and (max-width:$responsive){
			min-height: 1px;
            height: 240px;   
        }
        &.active{
            .firstview__container{
                .firstview__container__ttl{
                    opacity: 1;
                    span{
                        @for $i from 1 through 50{
                            &:nth-of-type(#{$i}){
                                opacity: 1;
                                transform: translateY(0) rotateY(0deg);
                                @include transition(500ms, $delay: 60ms * $i);
                            }
                        }
                    }
                }
            }
        }
        &__container{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 10;
            @include flex();
            justify-content: center;
            align-items: center;
            //background: rgba(#000,.08);
            &__ttl{
                color: #FFF;
                font-size: 72px;
                font-weight: 600;
                text-align: center;
                line-height: 1.4;
                opacity: 0;
                @media screen and (max-width:$responsive){
                    font-size: 28px;   
                }
                span{
                    display: inline-block;
                    opacity: 0;
                    transform: translateY(40px) rotateY(-90deg);
                }
            }
        }
        .fvswipe{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            transition: opacity 800ms;
            &.swiper-container-initialized{
                opacity: 1;
            }
            .bg{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-size: cover;
                background-position: center;
            }
			.swiper-slide{
				position: relative;
				overflow: hidden;
				video{
					position: absolute;
					top: 50%;
					left: 50%;
					width: 100%;
					transform: translate(-50%, -50%);
					@media screen and (max-width:$responsive){
						width: 100%;
						height: 100%;
						top: 0;
						left: 0;
						transform: translate(0);
						object-fit: cover;
						object-position: center;
					}
				}
			}
            .swiper-slide-active .bg,
            .swiper-slide-duplicate-active .bg,
            .swiper-slide-prev .bg{
                animation: zoomUp 8s linear 0s 1 normal both;  
            }
            @keyframes zoomUp {
                0% {
                    transform: scale(1.08);
                }
                100% {
                    transform: scale(1);
                }
            }
        }
    }
    
    .information{
        .flex{
            @include flex();
            @media screen and (max-width:$responsive){
                display: block;   
            }
            .leftside{
                width: 240px;
                @media screen and (max-width:$responsive){
                    width: 100%;
                    @include flex();
                    align-items: flex-end;
                }
                h3{
                    font-weight: 700;
                    font-size: 28px;
                    line-height: 1;
                    margin: 0 0 16px;
                    @media screen and (max-width:$responsive){
                        font-size: 20px;
                        margin: 0;
                    }
                }
                a{
                    display: inline-block;
                    color: $normal;
                    font-weight: 700;
                    &:hover{
                        text-decoration: underline;
                    }
                    i{
                        display: inline-block;
                        margin: 0 0 0 12px;
                    }
                }
            }
            .list{
                width: calc(100% - 240px);
                padding: 0 40px;
                height: 200px;
                overflow: auto;
                @media screen and (max-width:$responsive){
                    width: 100%;
                    height: 172px;
                    padding: 15px;
                    border: $border 1px solid;
                    border-radius: 3px;
                    margin: 15px 0 0;
                }
                li{
                    list-style: none;
                    padding: 0 0 20px;
                    margin: 0 0 20px;
                    border-bottom: $border 1px solid;
                    @media screen and (max-width:$responsive){
                        padding: 0 0 15px;
                        margin: 0 0 15px;
                    }
                    a{
                        @include flex();
                        align-items: center;
                        color: $normal;
                        @media screen and (max-width:$responsive){
                            justify-content: flex-start;   
                        }
                        &:hover{
                            h4{
                                text-decoration: underline;
                            }
                        }
                    }
                    time{
                        width: 16%;
                        font-weight: 600;
                        @media screen and (max-width:$responsive){
                            width: 88px;   
                        }
                    }
                    .category{
                        width: 14%;
                        font-size: 12px;
                        background: $normal;
                        color: #fff;
                        padding: 2px 0;
                        text-align: center;
                        @media screen and (max-width:$responsive){
                            width: 80px;
                            padding: 0;
                            font-size: 11px;
                        }
                    }
                    h4{
                        width: 70%;
                        font-size: 16px;
                        padding: 0 0 0 48px;
                        @media screen and (max-width:$responsive){
                            width: 100%;
                            padding: 8px 0 0;
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
    
    .concept{
        position: relative;
        &.mb{
            margin: 0 0 80px;
            @media screen and (max-width:$responsive){
                margin: 0;
                border-bottom: none !important;
            }
        }
        &.wrap{
            padding: 80px 0;
            @media screen and (max-width:$tab){
                padding: 64px 0;   
            }
            @media screen and (max-width:$responsive){
                padding: 0;
                padding-bottom: 40px;
                border-bottom: $border 1px solid;
            }
            .display{
                z-index: 2;
            }
        }
        &__bg{
            position: absolute;
            top: 0;
            left: 0;
            width: calc(50% + 80px);
            height: 100%;
            &--rvs{
                left: auto;
                right: 0;
            }
            @media screen and (max-width:$responsive){
                position: relative;
                width: 100%;
                height: 240px;
            }
            .bg{
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background-position: center;
                background-size: cover;
            }
        }
        &__data{
            width: calc(50% - 80px);
            margin: 0 0 0 calc(50% + 80px);
            @media screen and (max-width:$tab){
                padding: 0 20px;   
            }
            @media screen and (max-width:$responsive){
                width: 100%;
                margin: 24px 0 0;
                padding: 0;
            }
            h2{
                font-size: 122px;
                white-space: nowrap;
                transform: translateX(-47px);
                letter-spacing: .04em;
                line-height: 1;
                color: $light;
                font-weight: 600;
                text-shadow: none;
                @media screen and (max-width:$tab){
                    font-size: 94px;   
                }
                @media screen and (max-width:$responsive){
                    font-size: 32px;
                    transform: translateX(0);
                    margin: 15px 0 15px;
                    text-align: center;
                }
                &:first-letter{
                    color: $green;
                }
            }
            dl{
                padding: 24px 0 0 52px;
                @media screen and (max-width:$responsive){
                    padding: 0 15px;   
                }
                dt{
                    font-size: 22px;
                    font-weight: 700;
                    letter-spacing: 0;
                    line-height: 1.66;
                    @media screen and (max-width:$tab){
                        font-size: 18px;   
                    }
                    @media screen and (max-width:$responsive){
                        font-size: 16px;
                        text-align: center;
                    }
                }
                dd{
                    font-size: 15px;
                    text-align: justify;
                    margin: 20px 0 0;
                    @media screen and (max-width:$tab){
                        font-size: 13px;   
                    }
                    @media screen and (max-width:$responsive){
                        margin: 12px 0 0;   
                    }
                }
            }
            &--rvs{
                margin-left: 0;
                h2{
                    transform: translateX(-4px);
                }
                dl{
                    padding-left: 0;
                    padding-right: 52px;
                    @media screen and (max-width:$responsive){
                        padding: 0 15px;   
                    }
                }
            }

        }
    }
    
    .business{
        &.wrap{
            padding-left: 8%;
            @include flex();
            @media screen and (max-width:$responsive){
                padding-left: 15px;
                padding-right: 15px;
                display: block;
            }
        }
        .business__txt{
            width: 440px;
            padding: 0 80px 0 0;
            position: relative;
            @media screen and (max-width:$responsive){
                width: auto;
                padding: 0;
            }
            &__h{
                font-size: 71px;
                white-space: nowrap;
                line-height: 1;
                text-indent: -2px;
                letter-spacing: .06em;
                color: $light;
                font-weight: 600;
                text-shadow: none;
                @media screen and (max-width:$responsive){
                    font-size: 32px;
                    text-indent: 0;
                    text-align: center;
                    margin: 0 0 15px;
                }
                &:first-letter{
                    color: $green;
                }
            }
            &__description{
                dt{
                    font-size: 22px;
                    font-weight: 700;
                    margin: 20px 0 8px;
                    position: relative;
                    @media screen and (max-width:$responsive){
                        font-size: 17px;
                        text-align: center;
                        margin: 0 15px 8px;
                    }
                    &:before{
                        content: '';
                        width: 100%;
                        height: 1px;
                        background: $normal;
                        position:absolute;
                        top: 50%;
                        left: 0;
                        z-index: -1;
                        @media screen and (max-width:$responsive){
                            display: none;
                        }
                    }
                    &:after{
                        content: '';
                        width: 44%;
                        height: 100%;
                        background: #FFF;
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: -1;
                    }
                }
                dd{
                    font-size: 14px;
                    text-align: justify;
                }
            }
        }
        .swiper-wrapper{
            transition-timing-function: cubic-bezier(0.83, 0, 0.17, 1);
        }
        .buswipe-pager{
            @include flex();
            justify-content: flex-start;
            position: absolute;
            left: 0;
            bottom: 0;
            @media screen and (max-width:$responsive){
                display: none;
            }
            &__item{
                $size: 38px;
                width: $size;
                height: $size;
                text-align: center;
                background: $normal;
                border: $normal 1px solid;
                color: #FFF;
                margin: 0 12px 0 0;
                font-size: 12px;
                cursor: pointer;
                @include flex();
                align-items: center;
                justify-content: center;
                &:hover{
                    background: #FFF;
                    color: $normal;
                }
            }
        }
        .buswipe{
            width: calc(100% - 440px);
            @media screen and (max-width:$responsive){
                width: 100%;
                margin: 24px 0 0;
            }
            .swiper-slide{
                width: 256px;
                position: relative;
                display: block;
                color: $normal;
            }
            figure{
                border: $border 1px solid;
                img{
                    width: 100%;
                }
            }
            i{
                position: absolute;
                right: 0;
                bottom: 0;
                font-size: 16px;
                @media screen and (max-width:$responsive){
                    font-size: 13px;   
                }
            }
            dl{
                margin: 12px 0 0;
                dt{
                    font-size: 15px;
                    font-weight: 700;
                    @media screen and (max-width:$responsive){
                        font-size: 12px;   
                    }
                }
                dd{
                    line-height: 1;
                    @media screen and (max-width:$responsive){
                        display: none;
                    }
                }
            }
        }
    }
    
    .solution{
        background: #FFF;
        position: relative;
        overflow: hidden;
        &:before,
        #particles{
            content: '';
            background: $green;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 376px;
            z-index: 1;
        }
        &:after{
            content: '';
            width: 150%;
            left: -25%;
            position: absolute;
            bottom: 0;
            height: 62%;
            background: #fff;
            border-radius: 50%;
            z-index: 1;
            @media screen and (max-width:$responsive){
                border-radius: 0;   
            }
        }
        .display{
            z-index: 2;
        }
        &__list{
            @include flex();
            @media screen and (max-width:$responsive){
                display: block;   
            }
            &._active{
                li{
                    opacity: 1;
                    transform: translateY(0);
                    @for $i from 1 through 3{
                        &:nth-child(#{$i}){
                            @include transition(600ms, $delay:200ms*$i);
                        }
                    }
                }
            }
            li{
                width: 31%;
                background: #FFF;
                box-shadow: 0 4px 32px rgba(#000,.06);
                border-radius: 8px;
                overflow: hidden;
                opacity: 0;
                transform: translateY(40px);
                @media screen and (max-width:$responsive){
                    width: auto;
                    border-radius: 4px;
                    margin: 0 0 15px;
                    box-shadow: 0 4px 16px rgba(#000,.08);
                    &:last-child{
                        margin: 0;
                    }
                }
                figure{
                    img{
                        width: 100%;
                    }
                }
                dl{
                    padding: 20px;
                    dt{
                        text-align: center;
                        font-weight: 700;
                        font-size: 20px;
                        line-height: 1;
                        &:before{
                            content: attr(data-eng);
                            display: block;
                            font-size: 10px;
                            color: $green;
                            margin: 0 0 10px;
                        }
                    }
                    dd{
                        text-align: justify;
                        line-height: 1.6;
                        margin: 16px 0 0;
                    }
                }
            }
        }
    }
    
    .product{
        background: #FFF;
        position: relative;
        &:before{
            content: '';
            width: 100%;
            height: 64%;
            position: absolute;
            left: 0;
            bottom: 0;
            background: $glay;
        }
        &.wrap{
            padding-bottom: 40px;
        }
        .prswipe{
            padding-bottom: 64px;
            @media screen and (max-width:$responsive){
                padding-bottom: 48px;
            }
            .swiper-wrapper{
                transition-timing-function: cubic-bezier(0.83, 0, 0.17, 1);
            }
            .swiper-pagination{
                bottom: 0;
                .swiper-pagination-bullet{
                    margin: 0 6px;
                    &.swiper-pagination-bullet-active{
                        transform: scale(1.2);
                        background: $green;
                    }
                }
            }
            .swiper-slide{
                width: 280px;
                display: block;
                background: #FFF;
                color: $normal;
                transform: scale(.84);
                transition: transform 200ms;
                box-shadow: 0 4px 32px rgba(#000,.06);
                border-radius: 4px;
                overflow: hidden;
                &.swiper-slide-active{
                    transform: scale(1);
                }
                &.swiper-slide-next,
                &.swiper-slide-prev{
                    transform: scale(.92);
                }
                figure{
                    img{
                        width: 100%;
                    }
                }
                dl{
                    padding: 15px;
                    dt{
                        font-size: 16px;
                        font-weight: 700;
                    }
                    dd{
                        &.description{
                            font-size: 10px;
                        }
                        &.home{
                            margin: 12px 0 0;
                            padding: 12px 0 0;
                            border-top: $border 1px solid;
                            font-size: 12px;
                            i{
                                display: inline-block;
                                margin: 0 8px 0 0;
                            }
                        }
                    }
                }
            }
        }
    }
	
	.videoarea{
		margin: 0 0 104px;
		position: relative;
		@media screen and (max-width:$responsive){
			margin: 0 0 40px;	
		}
		&:before{
			content: '';
			width: 100%;
			height: 50%;
			position: absolute;
			left: 0;
			bottom: 0;
			background: $glay;
		}
		&__v{
			width: 800px;
			margin: 0 auto;
			@media screen and (max-width:$responsive){
				width: 100%;	
			}
			iframe{
				width: 100%;
				height: 400px;
				vertical-align: bottom;
				@media screen and (max-width:$responsive){
					height: 48vw;	
				}
			}
		}
	}
}