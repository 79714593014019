.site-recruit{
    .first{
        h2.catch{
            text-align: center;
            font-size: 28px;
            font-weight: 700;
            line-height: 1.5;
            @media screen and (max-width:$responsive){
                font-size: 20px;
                letter-spacing: 0em;
            }
            &:after{
                content: '';
                width: 40px;
                height: 1px;
                margin: 20px auto;
                display: block;
                background: $normal;
            }
        }
        h3.borders{
            background: $glay;
            text-align: center;
            padding: 15px 2px;
            font-size: 20px;
            font-weight: 700;
            @media screen and (max-width:$responsive){
                padding: 12px 2px;  
                margin: 0 0 16px;
                border-top: $border 4px solid;
            }
        }
        .context{
            text-align: center;
            font-weight: 700;
            font-size: 15px;
            margin: 0 0 32px;
            @media screen and (max-width:$responsive){
                margin: 0 15px 24px;
                font-size: 14px;
                text-align: justify;
            }
        }
        .clums{
            @include flex();
            align-items: center;
            margin: 0 0 64px;
            background: #FFF;
            border: $glay 20px solid;
            padding: 20px;
            @media screen and (max-width:$responsive){
                padding: 15px;
                border: darken($glay, 2%) 4px solid;
                margin: 0 0 32px;
            }
            &__pic{
                width: 400px;
                @media screen and (max-width:$responsive){
                    width: auto;
                    margin: 0 0 16px;
                    padding: 0 0 16px;
                    border-bottom: $border 1px solid;
                }
                img{
                    width: 100%;
                }
                figcaption{
                    font-size: 13px;
                    margin: 16px 0 0;
                }
            }
            &__text{
                width: calc(100% - 400px);
                padding: 0 0 0 48px;
                @media screen and (max-width:$responsive){
                    width: auto;
                    padding: 0;
                }
                .mb{
                    margin: 0 0 20px;
                    @media screen and (max-width:$responsive){
                        margin: 0 0 16px;   
                    }
                }
            }
        }
        .table-style{
            .mb{
                margin: 0 0 24px;
                @media screen and (max-width:$responsive){
                    margin: 0 0 20px;   
                }
                &.s{
                    margin: 0 0 16px;
                    @media screen and (max-width:$responsive){
                        margin: 0 0 12px;   
                    }
                }
            }
            .work{
                dt{
                    font-weight: 700;
                    font-size: 20px;
                    margin: 0 0 4px;
                    @media screen and (max-width:$responsive){
                        font-size: 18px;
                        line-height: 1.56;
                    }
                }
                dd{
                    font-size: 14px;
                }
            }
            .work-small{
                padding: 20px;
                background: $glay;
                border-radius: 4px;
                @media screen and (max-width:$responsive){
                    padding: 15px;   
                }
                dt{
                    font-size: 18px;
                    font-weight: 700;
                    margin: 0 0 8px;
                    padding: 0 0 0 20px;
                    position: relative;
                    @media screen and (max-width:$responsive){
                        font-size: 16px;   
                    }
                    &:before{
                        content: '';
                        $size: 12px;
                        width: $size;
                        height: $size;
                        border: $green 4px solid;
                        border-radius: 50%;
                        position: absolute;
                        top: 50%;
                        left: 0;
                        transform: translateY(-50%);
                    }
                }
                dd{
                    font-size: 14px;
                    @media screen and (max-width:$responsive){
                        padding: 0 0 12px;
                        margin: 0 0 12px;
                        border-bottom: darken($border, 3%) 1px solid;
                        &:last-of-type{
                            margin: 0;
                            padding: 0;
                            border: none;
                        }
                    }
                }
                &.square{
                    dd{
                        &:before{
                            content: '■';
                            margin: 0 8px 0 0;
                            display: inline-block;
                            opacity: .16;
                            @media screen and (max-width:$responsive){
                                opacity: .2;   
                            }
                        }
                    }
                }
                &.tryangle{
                    dd{
                        &:before{
                            content: '▼';
                            margin: 0 8px 0 0;
                            display: inline-block;
                            opacity: .16;
                            @media screen and (max-width:$responsive){
                                opacity: .2;   
                            }
                        }   
                    }
                }
            }
            .step{
                margin: 0 0 20px;
                padding: 0 0 20px;
                border-bottom: darken($border, 15%) 1px dashed;
                li{
                    margin: 0 0 12px;
                    &:last-child{
                        margin: 0;
                    }
                    @for $i from 1 through 3{
                        &:nth-child(#{$i}){
                            &:before{
                                content: 'STEP#{$i}';
                                border-radius: 999px;
                                display: inline-block;
                                width: 88px;
                                text-align: center;
                                margin: 0 12px 0 0;
                                background: $green;
                                font-weight: 700;
                                color: #FFF;
                            }
                        }
                    }
                }
            }
        }
        .photoarea{
            @include flex();
            margin: 40px 0 64px;
            padding: 20px;
            background: $glay;
            @media screen and (max-width:$responsive){
                margin: 20px 0 32px;
                padding: 0;
                background: none;
            }
            figure{
                width: calc(50% - 10px);
                background: #FFF;
                padding: 20px;
                @include flex();
                align-items: center;
                box-shadow: 0 0 16px rgba(#000,.05);
                @media screen and (max-width:$responsive){
                    width: auto;
                    padding: 0;
                    display: block;
                    box-shadow: none;
                    margin: 0 0 32px;
                    &:last-of-type{
                        margin: 0;
                    }
                }
                img{
                    width: 200px;
                    @media screen and (max-width:$responsive){
                        width: 100%;   
                    }
                }
                figcaption{
                    width: calc(100% - 200px);
                    padding: 0 0 0 24px;
                    text-align: justify;
                    @media screen and (max-width:$responsive){
                        width: auto;
                        padding: 15px 0 0;
                    }
                }
            }
        }
    }
	
	// LIST
	&.reclist{
		.listtitle{
			text-align: center;
			font-weight: 700;
			font-size: 32px;
			line-height: 1.5;
			margin: 0 0 24px;
			@media screen and (max-width:$responsive){
				font-size: 20px;
				margin: 0 0 16px;
			}
			&:after{
				content: attr(data-eng);
				font-size: 15px;
				letter-spacing: .12em;
				display: block;
				margin: 0 0 2px;
				@media screen and (max-width:$responsive){
					font-size: 12px;	
				}
			}
		}
		.listmessage{
			text-align: center;
			font-size: 16px;
			@media screen and (max-width:$responsive){
				font-size: 13px;
				text-align: justify;
				margin: 0 15px;
			}
		}
		.listable{
			background: $glay;
			padding: 32px;
			margin: 32px 0 0;
			@media screen and (max-width:$responsive){
				padding: 15px;
				margin: 24px 0 0;
				border-radius: 3px;
			}
			h3{
				line-height: 1.5;
				font-size: 32px;
				font-weight: 700;
				margin: 0 0 24px;
				text-align: center;
				@media screen and (max-width:$responsive){
					font-size: 20px;
					margin: 0 0 16px;
				}
			}
			&__list{
				margin: 0 0 0 -24px;
				@include flex();
				justify-content: flex-start;
				@media screen and (max-width:$responsive){
					margin: 0;
					display: block;
				}
				&__item{
					width: 33.3%;
					margin: 24px 0 0;
					padding: 0 0 0 24px;
					@media screen and (max-width:$responsive){
						width: auto;
						margin: 0 0 15px;
						padding: 0;
						&:last-child{
							margin: 0;
						}
					}
					&:nth-child(-n+3){
						margin-top: 0;
					}
					a{
						@include flex();
						align-items: center;
						color: $normal;
						padding: 15px;
						background: #FFF;
						border-radius: 4px;
						box-shadow: 0 0 16px rgba(#000,.08);
						position: relative;
						transition: transform 120ms ease-in-out;
						@media screen and (max-width:$responsive){
							padding: 12px 15px;	
						}
						&:hover{
							transform: scale(1.06);
							img{
								opacity: 1;
							}
						}
						&:after{
							content: "\f0a9";
							font-weight: 900;
							font-family: "Font Awesome 5 Free";
							position: absolute;
							top: 50%;
							right: 16px;
							transform: translateY(-50%);
						}
						figure{
							position: relative;
							width: 48px;
							height: 48px;
							overflow: hidden;
							border-radius: 50%;
							img{
								position: absolute;
								top: 50%;
								left: 50%;
								transform: translate(-50%,-50%);
								width: 100%;
							}
						}
						h4{
							width: calc(100% - 48px);
							padding: 0 0 0 20px;
							font-size: 15px;
							font-weight: 700;
						}
					}
				}
			}
		}
		.telbtn{
			margin: 48px 0 0;
			text-align: center;
			@media screen and (max-width:$responsive){
				margin: 24px 0 0;	
			}
			a{
				border: $normal 2px solid;
				border-radius: 999px;
				padding: 18px 0;
				color: $normal;
				font-size: 18px;
				line-height: 1;
				font-weight: 700;
				width: 480px;
				margin: 0 auto;
				display: block;
				transition: transform 160ms ease-in-out,
							box-shadow 160ms ease-in-out;
				@media screen and (max-width:$responsive){
					width: auto;
					display: block;
					font-size: 16px;
					border-radius: 3px;
				}
				&:hover{
					transform: scale(1.04);
					box-shadow: 0 6px 16px rgba(#000,.18);
				}
				.numb{
					display: block;
					margin: 10px 0 0;
					font-weight: 600;
					font-size: 15px;
				}
				i{
					display: inline-block;
					margin: 0 8px 0 0;
				}
			}
		}
	}
	
	&.recsingle{
		h2.borders,
		h3.borders,
		h4.borders{
			border-bottom: $border 1px solid;
			position: relative;
			padding: 12px;
			padding-left: 20px;
			font-size: 22px;
			line-height: 1;
			font-weight: 700;
			margin: 0 0 16px;
			@media screen and (max-width:$responsive){
				font-size: 18px;
				margin: 0 0 20px;
				padding-left: 18px;
			}
			small{
				display: inline-block;
				margin: 0 0 0 20px;
				font-size: 13px;
				@media screen and (max-width:$responsive){
					margin: 0 0 0 15px;
					font-size: 11px;
				}
			}
			&:after{
				content: '';
				width: 5px;
				height: 70%;
				background: $normal;
				border-radius: 4px;
				display: block;
				position: absolute;
				top: 15%;
				left: 0;
			}
		}
		.photolist{
			margin: 40px 0 80px -20px;
			@include flex();
			@media screen and (max-width:$responsive){
				margin: 24px 0;
				display: block;
			}
			li{
				width: 50%;
				padding: 0 0 0 20px;
				@media screen and (max-width:$responsive){
					width: auto;
					padding: 0;
					margin: 0 0 15px;
					&:last-child{
						margin: 0;
					}
				}
				figure{
					width: 100%;
					padding-top: 60%;
					position: relative;
					overflow: hidden;
					img{
						position: absolute;
						width: 100%;
						top: 50%;
						left: 50%;
						transform: translate(-50%,-50%);
					}
				}
			}
		}
	}
}