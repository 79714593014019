#site-footer{
    .contactnav{
        // border-top: $border 1px solid;
        background: #FFF;
        &__kussion{
            height: 340px;
            background: url("../../../images/common/kussion.jpg") no-repeat center;
            background-size: cover;
            @include flex();
            align-items: center;
            justify-content: center;
            position: relative;
            border-bottom: #FFF 2px solid;
            color: #FFF;
            @media screen and (max-width:$responsive){
                height: 300px;   
            }
            &:before{
                content: '';
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background: #000;
                opacity: .56;
            }
            dl{
                text-align: center;
                z-index: 2;
                dt{
                    font-size: 28px;
                    font-weight: 700;
                    margin: 0 0 8px;
                    @media screen and (max-width:$responsive){
                        font-size: 24px;  
                    }
                    &:before{
                        display: block;
                        content: 'CONTACT';
                        font-size: 12px;
                    }
                }
                dd{
                    font-size: 15px;
                    @media screen and (max-width:$responsive){
                        padding: 0 30px;   
                    }
                    &.btnarea{
                        margin: 24px 0 0;
                        @media screen and (max-width:$responsive){
                            padding: 0 15px;   
                        }
                        a{
                            background: #FFF;
                            border-color: #FFF;
                            color: $normal;
                            box-shadow: 0 0 32px rgba(#000,.08);
                            &:hover{
                                background: $normal;
                                border-color: $normal;
                                color: #FFF;
                            }
                        }
                    }
                }
            }
        }
        &__items{
            @include flex();
            border-left: $border 1px solid;
            border-right: $border 1px solid;
            li{
                width: 50%;
                &:nth-child(1){
                    border-right: $border 1px solid;
                }
                a{
                    display: block;
                    padding: 32px;
                    color: $normal;
                    font-weight: 700;
                    font-size: 17px;
                    position: relative;
                    &:after{
                        font-family: "Font Awesome 5 Free";
                        content: "\f054";
                        font-weight: 900;
                        font-size: 24px;
                        position: absolute;
                        top: 50%;
                        right: 32px;
                        -webkit-transform: translateY(-50%);
                        transform: translateY(-50%);
                        opacity: .16;
                    }
                    small{
                        display: inline-block;
                        margin: 0 0 0 8px;
                    }
                    i{
                        color: $green;
                        display: inline-block;
                        margin: 0 12px 0 0;
                    }
                }
            }
        }
        &__gmap{
            //border-top: $border 1px solid;
            height: 300px;
            @media screen and (max-width:$responsive){
                height: 180px;   
            }
            iframe{
                height: 100%;
                vertical-align: bottom;
                filter: grayscale(1);
            }
        }
    }
    
    .container{
        background: $normal;
        color: #FFF;
        &__pagetop{
            background: darken($normal, 6%);
            text-align: center;
            display: block;
            padding: 15px 0;
        }
        a{
            color: #FFF;
        }
        .display{
            padding: 64px 0;
            @media screen and (max-width:$responsive){
                padding: 24px 15px;   
            }
        }
        &__fnav{
            text-align: center;
            margin: 0 0 40px;
            @media screen and (max-width:$responsive){
                margin: 0 0 24px;
                border: rgba(#fff,.12) 1px solid;
                border-radius: 4px;
            }
            li{
                display: inline-block;
                line-height: 1;
                @media screen and (max-width:$responsive){
                    display: block;
                    border-bottom: rgba(#fff,.12) 1px solid;
                    &:last-child{
                        border-bottom: none;
                    }
                    a{
                        display: block;
                        text-align: left;
                        padding: 15px;
                        position: relative;
                        &:after{
                            content: "\f054";
                            font-weight: 900;
                            font-family: "Font Awesome 5 Free";
                            position: absolute;
                            top: 50%;
                            right: 15px;
                            transform: translateY(-50%);
                            opacity: .12;
                        }
                    }
                }
                &:after{
                    content: '|';
                    display: inline-block;
                    margin: 0 16px;
                    opacity: .24;
                    @media screen and (max-width:$responsive){
                        display: none;
                    }
                }
                &:last-child{
                    &:after{
                        display: none;
                    }
                }
            }
        }
        .copy{
            font-size: 11px;
            display: block;
            text-align: center;
        }
    }
}