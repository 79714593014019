#page-title{
    position: relative;
    overflow: hidden;
    &.active{
        .bg{
            transform: scale(1);
        }
        h1{
            opacity: 1;
            transform: translateY(0);
        }
    }
    .bg{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
        z-index: 1;
        transform: scale(1.04);
        @include transition(1600ms, $ease:linear);
    }
    &:before{
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: #000;
        opacity: .24;
        z-index: 2;
        @media screen and (max-width:$responsive){
            opacity: .48;   
        }
    }
    .display{
        z-index: 3;
        height: 340px;
        @include flex();
        align-items: center;
        justify-content: flex-start;
        @media screen and (max-width:$responsive){
            height: 160px;
            justify-content: center;
        }
    }
    h1{
        color: #FFF;
        font-size: 20px;
        font-weight: 600;
        line-height: 1;
        margin: 0 0 0 4%;
        text-shadow: 0 0 32px rgba(#000,.24);
        transform: translateY(20px);
        opacity: 0;
        @include transition(800ms, $delay:600ms);
        @media screen and (max-width:$tab){
            margin: 0 0 0 8%;   
        }
        @media screen and (max-width:$responsive){
            font-size: 16px;
            margin: 8px 0 0;
            text-align: center;
        }
        &:before{
            content: attr(data-eng);
            font-size: 80px;
            display: block;
            margin: 0 0 12px;
            text-indent: -3px;
            letter-spacing: .04em;
            @media screen and (max-width:$responsive){
                font-size: 30px;
                text-indent: 0;
                margin: 0 0 8px;
            }
        }
    }
}

#breadcrumb{
    background: $normal;
    color: #FFF;
    a{
        color: #FFF;
    }
    ol{
        padding: 20px 0;
        font-size: 13px;
        @include flex();
        align-items: center;
        justify-content: flex-start;
        @media screen and (max-width:$tab){
            padding: 20px;   
        }
        @media screen and (max-width:$responsive){
            padding: 15px;
            font-size: 12px;
        }
        li{
            &:first-child{
                a{
                    &:hover{
                        text-decoration: underline;
                    }
                    i{
                        display: inline-block;
                        margin: 0 8px 0 0;
                        font-size: 11px;
                        transform: translateY(-1px);
                        opacity: .4;
                    }
                }
            }
            &:last-child{
                &:after{
                    display: none;
                }
            }
            &:after{
                content: "\f054";
                font-family: "Font Awesome 5 Free";
                font-size: 8px;
                font-weight: 900;
                opacity: .4;
                display: inline-block;
                margin: 0 16px;
                -webkit-transform: translateY(-2px);
                transform: translateY(-2px);
            }
        }
    }
}