.site-product{
	.site-module.wrap.pab{
		margin-bottom: 88px;
		padding-bottom: 88px;
		border-bottom: $border 1px solid;
		@media screen and (max-width:$tab){
			margin-bottom: 48px;
			padding-bottom: 48px;
		}
	}
	.list{
		@include flex();
		justify-content: flex-start;
		margin: 56px 0 56px -40px;
		@media screen and (max-width:$responsive){
			margin: 24px 0 0 -15px;
		}
		&__item{
			width: 25%;
			padding: 0 0 0 40px;
			margin: 56px 0 0;
			@media screen and (max-width:$tab){
				width: 33.3%;
				&:nth-child(-n+3){
					margin-top: 0;
				}
			}
			@media screen and (max-width:$responsive){
				width: 50%;
				padding: 0 0 0 15px;
				margin: 0 0 24px;
			}
			@media screen and (min-width:$tab + 1px){
				&:nth-child(-n+4){
					margin-top: 0;
				}
			}
			a{
				display: block;
				height: auto;
				color: $normal;
			}
			figure{
				position: relative;
				width: 100%;
				padding: 100% 0 0;
				overflow: hidden;
				background: $glay;
				img{
					width: 100%;
					height: 100%;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					object-fit: contain;
					object-position: center;
				}
			}
			h3{
				text-align: center;
				font-size: 14.5px;
				line-height: 1.56;
				font-weight: 700;
				margin: 12px 0 0;
				@media screen and (max-width:$responsive){
					font-size: 11px;	
				}
			}
		}
	}
	
	.clums{
		@include flex();
		align-items: center;
		@media screen and (max-width:$responsive){
			display: block;	
		}
		&__pic{
			width: 400px;
			position: relative;
			background: $glay;
			@media screen and (max-width:$tab){
				width: 300px;	
			}
			@media screen and (max-width:$responsive){
				width: auto;	
			}
			img{
				width: 100%;
				height: auto;
				object-fit: contain;
				object-position: center;
			}
		}
		&__data{
			width: calc(100% - 400px);
			padding: 0 0 0 56px;
			@media screen and (max-width:$tab){
				width: calc(100% - 300px);
				padding: 0 0 0 48px;
			}
			@media screen and (max-width:$responsive){
				width: auto;
				padding: 24px 0 0;
			}
			&__title{
				font-size: 32px;
				font-weight: 700;
				line-height: 1.56;
				margin: 0 0 20px;
				padding: 0 0 12px;
				border-bottom: $border 1px solid;
				@media screen and (max-width:$tab){
					font-size: 24px;
					margin: 0 0 16px;
					padding: 0 0 10px;
				}
				@media screen and (max-width:$responsive){
					font-size: 18px;	
				}
			}
			&__desc{
				font-size: 15px;
				text-align: left;
				@media screen and (max-width:$tab){
					font-size: 14px;	
				}
			}
			.btnarea{
				margin: 24px 0 0;
				text-align: left;
				@media screen and (max-width:$tab){
					text-align: center;	
				}
				a{
					margin: 0 8px 8px 0;
				}
			}
		}
	}
	
	.sectitle{
		margin: 72px auto 24px;
		text-align: center;
		font-size: 32px;
		font-weight: 700;
		@media screen and (max-width:$tab){
			font-size: 24px;	
		}
		@media screen and (max-width:$responsive){
			margin: 56px auto 20px;
		}
	}
	
	.question{
		&__item{
			background: $glay;
			margin: 0 0 32px;
			&:last-child{
				margin-bottom: 0;
			}
			@for $i from 1 through 20{
				&:nth-child(#{$i}){
					dl{
						dt{
							&:before{
								content: 'Q.#{$i}';
							}
						}
					}
				}
			}
			dl{
				padding: 0 24px;
				@media screen and (max-width:$responsive){
					padding: 0 15px;	
				}
				dt,dd{
					padding: 24px 0;
					@media screen and (max-width:$responsive){
						padding: 15px 0;	
					}
				}
				dt{
					font-size: 16px;
					line-height: 1.6;
					font-weight: 700;
					border-bottom: #FFF 4px solid;
					padding-left: 64px;
					position: relative;
					&:before{
						position: absolute;
						top: 22px;
						left: 0;
						font-size: 13px;
						width: 48px;
						font-weight: 500;
						text-align: center;
						padding: 4px 0;
						background: $normal;
						border-radius: 3px;
						color: #FFF;
					}
				}
				dd{
					font-size: 15px;
				}
			}
		}
	}
}