@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap");
html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

body {
  line-height: 1; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

nav ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: none; }

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none; }

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold; }

del {
  text-decoration: line-through; }

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0; }

input, select {
  vertical-align: middle; }

main {
  display: block; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 100; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 200; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 300; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 400; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 500; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Bold");
  font-weight: bold; }

/* BASE COLOR
------------------------------------------------------------*/
/* SITE SETTING
------------------------------------------------------------*/
@media screen and (min-width: 981px) {
  * {
    margin: 0;
    padding: 0;
    zoom: 1; }
  *, *:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box; }
  html {
    -webkit-text-size-adjust: none; }
  body {
    color: #202020;
    background: #FFF;
    font-size: 14px;
    line-height: 1.8;
    font-family: 'Poppins',"Yu Gothic","游ゴシック",YuGothic,"Hiragino Kaku Gothic Pro","メイリオ", Meiryo,"MS Pゴシック", Helvetica, Arial, Verdana, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt";
    font-feature-settings: "palt";
    letter-spacing: 0.024em; }
  h1, h2, h3, h4, h5, h6 {
    font-weight: 500; }
  img {
    -ms-interpolation-mode: bicubic;
    vertical-align: bottom; }
  i, strong {
    font-style: normal; }
  a {
    text-decoration: none;
    outline: none;
    cursor: pointer;
    color: #D02; }
    a:hover {
      text-decoration: none;
      color: #ff1136; }
      a:hover img {
        opacity: .8; }
  .clear {
    clear: both; }
  .clearfix:after {
    content: "";
    display: block;
    clear: both; }
  .display {
    width: 1120px;
    margin: 0 auto;
    position: relative;
    top: 0;
    left: 0; }
  ul, ol {
    list-style: none; }
  input[type="text"],
  input[type="tel"],
  input[type="email"],
  input[type="submit"],
  textarea {
    -webkit-appearance: none; } }

@media screen and (max-width: 1119px) {
  * {
    margin: 0;
    padding: 0;
    zoom: 1; }
  *, *:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box; }
  html {
    -webkit-text-size-adjust: none; }
  body {
    color: #202020;
    background: #FFF;
    font-size: 13px;
    line-height: 1.8;
    font-family: 'Poppins',"Yu Gothic","游ゴシック",YuGothic,"Hiragino Kaku Gothic Pro","メイリオ", Meiryo,"MS Pゴシック", Helvetica, Arial, Verdana, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt";
    font-feature-settings: "palt";
    letter-spacing: 0.024em; }
  h1, h2, h3, h4, h5, h6 {
    font-weight: 500; }
  img {
    -ms-interpolation-mode: bicubic;
    vertical-align: bottom; }
  i, strong {
    font-style: normal; }
  a {
    text-decoration: none;
    outline: none;
    cursor: pointer;
    color: #D02; }
    a:hover {
      text-decoration: none;
      color: #ff1136; }
      a:hover img {
        opacity: .8; }
  .clear {
    clear: both; }
  .clearfix:after {
    content: "";
    display: block;
    clear: both; }
  .display {
    width: auto;
    margin: 0 auto;
    position: relative;
    top: 0;
    left: 0; }
  ul, ol {
    list-style: none; }
  input[type="text"],
  input[type="tel"],
  input[type="email"],
  input[type="submit"],
  textarea {
    -webkit-appearance: none; } }

@media screen and (max-width: 980px) {
  * {
    margin: 0;
    padding: 0;
    zoom: 1; }
  *, *:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box; }
  html {
    -webkit-text-size-adjust: none; }
  body {
    color: #202020;
    background: #FFF;
    font-size: 13px;
    line-height: 1.8;
    font-family: 'Poppins',"Yu Gothic","游ゴシック",YuGothic,"Hiragino Kaku Gothic Pro","メイリオ", Meiryo,"MS Pゴシック", Helvetica, Arial, Verdana, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt";
    font-feature-settings: "palt";
    letter-spacing: 0.024em; }
  h1, h2, h3, h4, h5, h6 {
    font-weight: 500; }
  img {
    -ms-interpolation-mode: bicubic;
    vertical-align: bottom; }
  i, strong {
    font-style: normal; }
  a {
    text-decoration: none;
    outline: none;
    cursor: pointer;
    color: #D02; }
    a:hover {
      text-decoration: none;
      color: #ff1136; }
      a:hover img {
        opacity: .8; }
  .clear {
    clear: both; }
  .clearfix:after {
    content: "";
    display: block;
    clear: both; }
  .display {
    width: auto;
    margin: 0 auto;
    position: relative;
    top: 0;
    left: 0; }
  ul, ol {
    list-style: none; }
  input[type="text"],
  input[type="tel"],
  input[type="email"],
  input[type="submit"],
  textarea {
    -webkit-appearance: none; } }

::selection {
  background: #202020;
  color: #FFF; }

body.windows {
  text-shadow: 0 0 rgba(0, 0, 0, 0.56); }

#site-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 78px;
  background: #FFF;
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
  transition: box-shadow 200ms; }
  @media screen and (max-width: 980px) {
    #site-header {
      height: 60px; } }
  #site-header:hover {
    box-shadow: 0 0 32px rgba(0, 0, 0, 0.1); }
  #site-header h1 {
    width: 124px;
    margin: 2px 0 0 48px; }
    @media screen and (max-width: 1119px) {
      #site-header h1 {
        margin-left: 20px; } }
    @media screen and (max-width: 980px) {
      #site-header h1 {
        width: 100px;
        margin-left: 15px; } }
    #site-header h1 img {
      width: 100%; }
  @media screen and (min-width: 981px) {
    #site-header .navigations {
      display: block !important; } }
  @media screen and (max-width: 980px) {
    #site-header .navigations {
      position: relative;
      z-index: 10; } }
  #site-header .close {
    display: none; }
    @media screen and (max-width: 980px) {
      #site-header .close {
        width: 40px;
        height: 40px;
        top: 9px;
        right: 14px;
        position: absolute;
        border-radius: 50%;
        cursor: pointer;
        display: block;
        z-index: 9999; }
        #site-header .close.active {
          animation: shows 600ms forwards; }
      @keyframes shows {
        0% {
          opacity: 0;
          transform: rotate(45deg); }
        100% {
          transform: rotate(0deg);
          opacity: 1; } }
        #site-header .close:before, #site-header .close:after {
          content: '';
          width: 60%;
          height: 1.5px;
          background: #FFF;
          position: absolute;
          top: 50%;
          left: 20%;
          transform: rotate(45deg); }
        #site-header .close:after {
          transform: rotate(-45deg); } }
  @media screen and (max-width: 980px) {
    #site-header .gnavi {
      width: 40px;
      height: 40px;
      background: #202020;
      margin: 0 15px 0 0;
      position: relative;
      border-radius: 50%;
      cursor: pointer; }
      #site-header .gnavi:before, #site-header .gnavi:after {
        content: '';
        width: 40%;
        height: 2px;
        background: #FFF;
        position: absolute;
        top: 40%;
        left: 30%;
        z-index: 2; }
      #site-header .gnavi:after {
        top: auto;
        bottom: 40%; } }
  #site-header .gnavi .navigations > ul {
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: center;
    justify-content: flex-start; }
    @media screen and (max-width: 980px) {
      #site-header .gnavi .navigations > ul {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 9500;
        width: 100%;
        height: 100%;
        overflow: auto;
        background: #202020;
        color: #FFF;
        font-size: 16px;
        padding: 80px 0 0;
        display: block; }
        #site-header .gnavi .navigations > ul a {
          color: #FFF; } }
    #site-header .gnavi .navigations > ul > li {
      list-style: none;
      padding: 0 64px 0 0;
      position: relative; }
      @media screen and (max-width: 1119px) {
        #site-header .gnavi .navigations > ul > li {
          padding: 0 48px 0 0; } }
      @media screen and (max-width: 980px) {
        #site-header .gnavi .navigations > ul > li {
          padding: 0 15px;
          border-bottom: rgba(255, 255, 255, 0.12) 1px solid; } }
      #site-header .gnavi .navigations > ul > li:after {
        content: '';
        width: 2px;
        height: 50%;
        background: #e8e8e8;
        position: absolute;
        top: 25%;
        right: 32px;
        transform: translateX(-50%) skewX(-24deg); }
        @media screen and (max-width: 1119px) {
          #site-header .gnavi .navigations > ul > li:after {
            right: 24px; } }
        @media screen and (max-width: 980px) {
          #site-header .gnavi .navigations > ul > li:after {
            display: none; } }
      #site-header .gnavi .navigations > ul > li:nth-last-child(-n+2):after {
        display: none; }
      @media screen and (min-width: 981px) {
        #site-header .gnavi .navigations > ul > li.contact {
          padding: 0;
          background: #202020; }
          #site-header .gnavi .navigations > ul > li.contact a {
            width: 124.8px;
            height: 78px;
            color: #FFF;
            display: -webkit-flex;
            display: -moz-flex;
            display: -o-flex;
            display: -ms-flex;
            display: flex;
            -moz-flex-flow: row wrap;
            -webkit-flex-flow: row wrap;
            -o-flex-flow: row wrap;
            -ms-flex-flow: row wrap;
            flex-flow: row wrap;
            -webkit-justify-content: space-between;
            -o-justify-content: space-between;
            -ms-justify-content: space-between;
            -moz-justify-content: space-between;
            justify-content: space-between;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-size: 12px;
            font-weight: 400;
            letter-spacing: .048em; } }
    @media screen and (min-width: 981px) and (max-width: 1119px) {
      #site-header .gnavi .navigations > ul > li.contact a {
        width: 93.6px; } }
      @media screen and (min-width: 981px) {
            #site-header .gnavi .navigations > ul > li.contact a span {
              display: block;
              position: relative;
              z-index: 2; }
              #site-header .gnavi .navigations > ul > li.contact a span i {
                width: 100%;
                display: block;
                font-size: 18px;
                margin: 0 0 6px; }
            #site-header .gnavi .navigations > ul > li.contact a:before {
              display: none; }
            #site-header .gnavi .navigations > ul > li.contact a:after {
              content: '';
              width: 100%;
              height: 0;
              position: absolute;
              bottom: 0;
              left: 0;
              background: #00cbb6;
              z-index: 1;
              -webkit-transition: all 200ms 0ms ease-in-out;
              -moz-transition: all 200ms 0ms ease-in-out;
              -ms-transition: all 200ms 0ms ease-in-out;
              -o-transition: all 200ms 0ms ease-in-out;
              transition: all 200ms 0ms ease-in-out; }
            #site-header .gnavi .navigations > ul > li.contact a:hover:after {
              height: 100%; } }
      @media screen and (max-width: 980px) {
        #site-header .gnavi .navigations > ul > li.contact span > i {
          display: none; } }
      #site-header .gnavi .navigations > ul > li > a {
        color: #202020;
        font-weight: 600;
        position: relative; }
        #site-header .gnavi .navigations > ul > li > a > i {
          display: none; }
        @media screen and (max-width: 980px) {
          #site-header .gnavi .navigations > ul > li > a {
            color: #FFF;
            padding: 15px;
            display: block;
            position: relative; }
            #site-header .gnavi .navigations > ul > li > a > i {
              display: block;
              position: absolute;
              top: 50%;
              right: 15px;
              font-size: 10px;
              opacity: .64;
              transform: translateY(-50%); } }
        #site-header .gnavi .navigations > ul > li > a:before {
          content: '';
          width: 0;
          height: 4px;
          background: #00cbb6;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: -29px;
          -webkit-transition: all 160ms 0ms ease-in-out;
          -moz-transition: all 160ms 0ms ease-in-out;
          -ms-transition: all 160ms 0ms ease-in-out;
          -o-transition: all 160ms 0ms ease-in-out;
          transition: all 160ms 0ms ease-in-out; }
          @media screen and (max-width: 980px) {
            #site-header .gnavi .navigations > ul > li > a:before {
              display: none; } }
        #site-header .gnavi .navigations > ul > li > a:hover:before {
          width: 40px; }
    #site-header .gnavi .navigations > ul dl {
      position: relative; }
      @media screen and (max-width: 980px) {
        #site-header .gnavi .navigations > ul dl {
          padding: 15px; } }
      #site-header .gnavi .navigations > ul dl dt {
        font-weight: 600;
        position: relative;
        cursor: pointer; }
        #site-header .gnavi .navigations > ul dl dt > i {
          display: none; }
          @media screen and (max-width: 980px) {
            #site-header .gnavi .navigations > ul dl dt > i {
              display: block;
              position: absolute;
              top: 50%;
              right: 0;
              font-size: 10px;
              opacity: .64;
              transform: translateY(-50%); } }
      #site-header .gnavi .navigations > ul dl dd {
        position: absolute;
        top: 52px;
        left: -20%;
        width: 140%;
        display: none; }
        @media screen and (max-width: 980px) {
          #site-header .gnavi .navigations > ul dl dd {
            position: relative;
            top: 0;
            left: 0;
            width: auto;
            padding: 15px 0 0; }
            #site-header .gnavi .navigations > ul dl dd.active {
              display: block; } }
        #site-header .gnavi .navigations > ul dl dd .child {
          box-shadow: 0 8px 8px rgba(0, 0, 0, 0.08); }
          @media screen and (max-width: 980px) {
            #site-header .gnavi .navigations > ul dl dd .child {
              box-shadow: none;
              background: rgba(255, 255, 255, 0.05); } }
          #site-header .gnavi .navigations > ul dl dd .child li {
            border-top: #e8e8e8 1px solid; }
            @media screen and (max-width: 980px) {
              #site-header .gnavi .navigations > ul dl dd .child li {
                border-top: none;
                border-bottom: rgba(255, 255, 255, 0.08) 1px solid; }
                #site-header .gnavi .navigations > ul dl dd .child li:last-child {
                  border: none; } }
            #site-header .gnavi .navigations > ul dl dd .child li:first-child {
              border-top: none; }
            #site-header .gnavi .navigations > ul dl dd .child li a {
              background: #FFF;
              display: block;
              padding: 12px 15px;
              color: #202020;
              text-align: center;
              font-weight: 600; }
              @media screen and (max-width: 980px) {
                #site-header .gnavi .navigations > ul dl dd .child li a {
                  background: none;
                  text-align: left;
                  color: #FFF;
                  font-size: 14px; } }
              #site-header .gnavi .navigations > ul dl dd .child li a i {
                display: inline-block;
                margin: 0 0 0 10px;
                font-size: 11px;
                opacity: .24; }
              #site-header .gnavi .navigations > ul dl dd .child li a:hover {
                color: #00cbb6; }
                #site-header .gnavi .navigations > ul dl dd .child li a:hover i {
                  opacity: 1; }
      @media screen and (min-width: 981px) {
        #site-header .gnavi .navigations > ul dl:hover dt:before {
          content: '';
          width: 140%;
          height: 78px;
          position: absolute;
          top: -25px;
          left: -20%; }
        #site-header .gnavi .navigations > ul dl:hover dd {
          display: block; } }

#site-footer .contactnav {
  background: #FFF; }
  #site-footer .contactnav__kussion {
    height: 340px;
    background: url("../../../images/common/kussion.jpg") no-repeat center;
    background-size: cover;
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
    position: relative;
    border-bottom: #FFF 2px solid;
    color: #FFF; }
    @media screen and (max-width: 980px) {
      #site-footer .contactnav__kussion {
        height: 300px; } }
    #site-footer .contactnav__kussion:before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: #000;
      opacity: .56; }
    #site-footer .contactnav__kussion dl {
      text-align: center;
      z-index: 2; }
      #site-footer .contactnav__kussion dl dt {
        font-size: 28px;
        font-weight: 700;
        margin: 0 0 8px; }
        @media screen and (max-width: 980px) {
          #site-footer .contactnav__kussion dl dt {
            font-size: 24px; } }
        #site-footer .contactnav__kussion dl dt:before {
          display: block;
          content: 'CONTACT';
          font-size: 12px; }
      #site-footer .contactnav__kussion dl dd {
        font-size: 15px; }
        @media screen and (max-width: 980px) {
          #site-footer .contactnav__kussion dl dd {
            padding: 0 30px; } }
        #site-footer .contactnav__kussion dl dd.btnarea {
          margin: 24px 0 0; }
          @media screen and (max-width: 980px) {
            #site-footer .contactnav__kussion dl dd.btnarea {
              padding: 0 15px; } }
          #site-footer .contactnav__kussion dl dd.btnarea a {
            background: #FFF;
            border-color: #FFF;
            color: #202020;
            box-shadow: 0 0 32px rgba(0, 0, 0, 0.08); }
            #site-footer .contactnav__kussion dl dd.btnarea a:hover {
              background: #202020;
              border-color: #202020;
              color: #FFF; }
  #site-footer .contactnav__items {
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    border-left: #e8e8e8 1px solid;
    border-right: #e8e8e8 1px solid; }
    #site-footer .contactnav__items li {
      width: 50%; }
      #site-footer .contactnav__items li:nth-child(1) {
        border-right: #e8e8e8 1px solid; }
      #site-footer .contactnav__items li a {
        display: block;
        padding: 32px;
        color: #202020;
        font-weight: 700;
        font-size: 17px;
        position: relative; }
        #site-footer .contactnav__items li a:after {
          font-family: "Font Awesome 5 Free";
          content: "\f054";
          font-weight: 900;
          font-size: 24px;
          position: absolute;
          top: 50%;
          right: 32px;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
          opacity: .16; }
        #site-footer .contactnav__items li a small {
          display: inline-block;
          margin: 0 0 0 8px; }
        #site-footer .contactnav__items li a i {
          color: #00cbb6;
          display: inline-block;
          margin: 0 12px 0 0; }
  #site-footer .contactnav__gmap {
    height: 300px; }
    @media screen and (max-width: 980px) {
      #site-footer .contactnav__gmap {
        height: 180px; } }
    #site-footer .contactnav__gmap iframe {
      height: 100%;
      vertical-align: bottom;
      filter: grayscale(1); }

#site-footer .container {
  background: #202020;
  color: #FFF; }
  #site-footer .container__pagetop {
    background: #111111;
    text-align: center;
    display: block;
    padding: 15px 0; }
  #site-footer .container a {
    color: #FFF; }
  #site-footer .container .display {
    padding: 64px 0; }
    @media screen and (max-width: 980px) {
      #site-footer .container .display {
        padding: 24px 15px; } }
  #site-footer .container__fnav {
    text-align: center;
    margin: 0 0 40px; }
    @media screen and (max-width: 980px) {
      #site-footer .container__fnav {
        margin: 0 0 24px;
        border: rgba(255, 255, 255, 0.12) 1px solid;
        border-radius: 4px; } }
    #site-footer .container__fnav li {
      display: inline-block;
      line-height: 1; }
      @media screen and (max-width: 980px) {
        #site-footer .container__fnav li {
          display: block;
          border-bottom: rgba(255, 255, 255, 0.12) 1px solid; }
          #site-footer .container__fnav li:last-child {
            border-bottom: none; }
          #site-footer .container__fnav li a {
            display: block;
            text-align: left;
            padding: 15px;
            position: relative; }
            #site-footer .container__fnav li a:after {
              content: "\f054";
              font-weight: 900;
              font-family: "Font Awesome 5 Free";
              position: absolute;
              top: 50%;
              right: 15px;
              transform: translateY(-50%);
              opacity: .12; } }
      #site-footer .container__fnav li:after {
        content: '|';
        display: inline-block;
        margin: 0 16px;
        opacity: .24; }
        @media screen and (max-width: 980px) {
          #site-footer .container__fnav li:after {
            display: none; } }
      #site-footer .container__fnav li:last-child:after {
        display: none; }
  #site-footer .container .copy {
    font-size: 11px;
    display: block;
    text-align: center; }

#page-title {
  position: relative;
  overflow: hidden; }
  #page-title.active .bg {
    transform: scale(1); }
  #page-title.active h1 {
    opacity: 1;
    transform: translateY(0); }
  #page-title .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    z-index: 1;
    transform: scale(1.04);
    -webkit-transition: all 1600ms 0ms linear;
    -moz-transition: all 1600ms 0ms linear;
    -ms-transition: all 1600ms 0ms linear;
    -o-transition: all 1600ms 0ms linear;
    transition: all 1600ms 0ms linear; }
  #page-title:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #000;
    opacity: .24;
    z-index: 2; }
    @media screen and (max-width: 980px) {
      #page-title:before {
        opacity: .48; } }
  #page-title .display {
    z-index: 3;
    height: 340px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: center;
    justify-content: flex-start; }
    @media screen and (max-width: 980px) {
      #page-title .display {
        height: 160px;
        justify-content: center; } }
  #page-title h1 {
    color: #FFF;
    font-size: 20px;
    font-weight: 600;
    line-height: 1;
    margin: 0 0 0 4%;
    text-shadow: 0 0 32px rgba(0, 0, 0, 0.24);
    transform: translateY(20px);
    opacity: 0;
    -webkit-transition: all 800ms 600ms ease-in-out;
    -moz-transition: all 800ms 600ms ease-in-out;
    -ms-transition: all 800ms 600ms ease-in-out;
    -o-transition: all 800ms 600ms ease-in-out;
    transition: all 800ms 600ms ease-in-out; }
    @media screen and (max-width: 1119px) {
      #page-title h1 {
        margin: 0 0 0 8%; } }
    @media screen and (max-width: 980px) {
      #page-title h1 {
        font-size: 16px;
        margin: 8px 0 0;
        text-align: center; } }
    #page-title h1:before {
      content: attr(data-eng);
      font-size: 80px;
      display: block;
      margin: 0 0 12px;
      text-indent: -3px;
      letter-spacing: .04em; }
      @media screen and (max-width: 980px) {
        #page-title h1:before {
          font-size: 30px;
          text-indent: 0;
          margin: 0 0 8px; } }

#breadcrumb {
  background: #202020;
  color: #FFF; }
  #breadcrumb a {
    color: #FFF; }
  #breadcrumb ol {
    padding: 20px 0;
    font-size: 13px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: center;
    justify-content: flex-start; }
    @media screen and (max-width: 1119px) {
      #breadcrumb ol {
        padding: 20px; } }
    @media screen and (max-width: 980px) {
      #breadcrumb ol {
        padding: 15px;
        font-size: 12px; } }
    #breadcrumb ol li:first-child a:hover {
      text-decoration: underline; }
    #breadcrumb ol li:first-child a i {
      display: inline-block;
      margin: 0 8px 0 0;
      font-size: 11px;
      transform: translateY(-1px);
      opacity: .4; }
    #breadcrumb ol li:last-child:after {
      display: none; }
    #breadcrumb ol li:after {
      content: "\f054";
      font-family: "Font Awesome 5 Free";
      font-size: 8px;
      font-weight: 900;
      opacity: .4;
      display: inline-block;
      margin: 0 16px;
      -webkit-transform: translateY(-2px);
      transform: translateY(-2px); }

.site-index .firstview {
  height: 56vh;
  min-height: 560px;
  position: relative; }
  @media screen and (max-width: 980px) {
    .site-index .firstview {
      min-height: 1px;
      height: 240px; } }
  .site-index .firstview.active .firstview__container .firstview__container__ttl {
    opacity: 1; }
    .site-index .firstview.active .firstview__container .firstview__container__ttl span:nth-of-type(1) {
      opacity: 1;
      transform: translateY(0) rotateY(0deg);
      -webkit-transition: all 500ms 60ms ease-in-out;
      -moz-transition: all 500ms 60ms ease-in-out;
      -ms-transition: all 500ms 60ms ease-in-out;
      -o-transition: all 500ms 60ms ease-in-out;
      transition: all 500ms 60ms ease-in-out; }
    .site-index .firstview.active .firstview__container .firstview__container__ttl span:nth-of-type(2) {
      opacity: 1;
      transform: translateY(0) rotateY(0deg);
      -webkit-transition: all 500ms 120ms ease-in-out;
      -moz-transition: all 500ms 120ms ease-in-out;
      -ms-transition: all 500ms 120ms ease-in-out;
      -o-transition: all 500ms 120ms ease-in-out;
      transition: all 500ms 120ms ease-in-out; }
    .site-index .firstview.active .firstview__container .firstview__container__ttl span:nth-of-type(3) {
      opacity: 1;
      transform: translateY(0) rotateY(0deg);
      -webkit-transition: all 500ms 180ms ease-in-out;
      -moz-transition: all 500ms 180ms ease-in-out;
      -ms-transition: all 500ms 180ms ease-in-out;
      -o-transition: all 500ms 180ms ease-in-out;
      transition: all 500ms 180ms ease-in-out; }
    .site-index .firstview.active .firstview__container .firstview__container__ttl span:nth-of-type(4) {
      opacity: 1;
      transform: translateY(0) rotateY(0deg);
      -webkit-transition: all 500ms 240ms ease-in-out;
      -moz-transition: all 500ms 240ms ease-in-out;
      -ms-transition: all 500ms 240ms ease-in-out;
      -o-transition: all 500ms 240ms ease-in-out;
      transition: all 500ms 240ms ease-in-out; }
    .site-index .firstview.active .firstview__container .firstview__container__ttl span:nth-of-type(5) {
      opacity: 1;
      transform: translateY(0) rotateY(0deg);
      -webkit-transition: all 500ms 300ms ease-in-out;
      -moz-transition: all 500ms 300ms ease-in-out;
      -ms-transition: all 500ms 300ms ease-in-out;
      -o-transition: all 500ms 300ms ease-in-out;
      transition: all 500ms 300ms ease-in-out; }
    .site-index .firstview.active .firstview__container .firstview__container__ttl span:nth-of-type(6) {
      opacity: 1;
      transform: translateY(0) rotateY(0deg);
      -webkit-transition: all 500ms 360ms ease-in-out;
      -moz-transition: all 500ms 360ms ease-in-out;
      -ms-transition: all 500ms 360ms ease-in-out;
      -o-transition: all 500ms 360ms ease-in-out;
      transition: all 500ms 360ms ease-in-out; }
    .site-index .firstview.active .firstview__container .firstview__container__ttl span:nth-of-type(7) {
      opacity: 1;
      transform: translateY(0) rotateY(0deg);
      -webkit-transition: all 500ms 420ms ease-in-out;
      -moz-transition: all 500ms 420ms ease-in-out;
      -ms-transition: all 500ms 420ms ease-in-out;
      -o-transition: all 500ms 420ms ease-in-out;
      transition: all 500ms 420ms ease-in-out; }
    .site-index .firstview.active .firstview__container .firstview__container__ttl span:nth-of-type(8) {
      opacity: 1;
      transform: translateY(0) rotateY(0deg);
      -webkit-transition: all 500ms 480ms ease-in-out;
      -moz-transition: all 500ms 480ms ease-in-out;
      -ms-transition: all 500ms 480ms ease-in-out;
      -o-transition: all 500ms 480ms ease-in-out;
      transition: all 500ms 480ms ease-in-out; }
    .site-index .firstview.active .firstview__container .firstview__container__ttl span:nth-of-type(9) {
      opacity: 1;
      transform: translateY(0) rotateY(0deg);
      -webkit-transition: all 500ms 540ms ease-in-out;
      -moz-transition: all 500ms 540ms ease-in-out;
      -ms-transition: all 500ms 540ms ease-in-out;
      -o-transition: all 500ms 540ms ease-in-out;
      transition: all 500ms 540ms ease-in-out; }
    .site-index .firstview.active .firstview__container .firstview__container__ttl span:nth-of-type(10) {
      opacity: 1;
      transform: translateY(0) rotateY(0deg);
      -webkit-transition: all 500ms 600ms ease-in-out;
      -moz-transition: all 500ms 600ms ease-in-out;
      -ms-transition: all 500ms 600ms ease-in-out;
      -o-transition: all 500ms 600ms ease-in-out;
      transition: all 500ms 600ms ease-in-out; }
    .site-index .firstview.active .firstview__container .firstview__container__ttl span:nth-of-type(11) {
      opacity: 1;
      transform: translateY(0) rotateY(0deg);
      -webkit-transition: all 500ms 660ms ease-in-out;
      -moz-transition: all 500ms 660ms ease-in-out;
      -ms-transition: all 500ms 660ms ease-in-out;
      -o-transition: all 500ms 660ms ease-in-out;
      transition: all 500ms 660ms ease-in-out; }
    .site-index .firstview.active .firstview__container .firstview__container__ttl span:nth-of-type(12) {
      opacity: 1;
      transform: translateY(0) rotateY(0deg);
      -webkit-transition: all 500ms 720ms ease-in-out;
      -moz-transition: all 500ms 720ms ease-in-out;
      -ms-transition: all 500ms 720ms ease-in-out;
      -o-transition: all 500ms 720ms ease-in-out;
      transition: all 500ms 720ms ease-in-out; }
    .site-index .firstview.active .firstview__container .firstview__container__ttl span:nth-of-type(13) {
      opacity: 1;
      transform: translateY(0) rotateY(0deg);
      -webkit-transition: all 500ms 780ms ease-in-out;
      -moz-transition: all 500ms 780ms ease-in-out;
      -ms-transition: all 500ms 780ms ease-in-out;
      -o-transition: all 500ms 780ms ease-in-out;
      transition: all 500ms 780ms ease-in-out; }
    .site-index .firstview.active .firstview__container .firstview__container__ttl span:nth-of-type(14) {
      opacity: 1;
      transform: translateY(0) rotateY(0deg);
      -webkit-transition: all 500ms 840ms ease-in-out;
      -moz-transition: all 500ms 840ms ease-in-out;
      -ms-transition: all 500ms 840ms ease-in-out;
      -o-transition: all 500ms 840ms ease-in-out;
      transition: all 500ms 840ms ease-in-out; }
    .site-index .firstview.active .firstview__container .firstview__container__ttl span:nth-of-type(15) {
      opacity: 1;
      transform: translateY(0) rotateY(0deg);
      -webkit-transition: all 500ms 900ms ease-in-out;
      -moz-transition: all 500ms 900ms ease-in-out;
      -ms-transition: all 500ms 900ms ease-in-out;
      -o-transition: all 500ms 900ms ease-in-out;
      transition: all 500ms 900ms ease-in-out; }
    .site-index .firstview.active .firstview__container .firstview__container__ttl span:nth-of-type(16) {
      opacity: 1;
      transform: translateY(0) rotateY(0deg);
      -webkit-transition: all 500ms 960ms ease-in-out;
      -moz-transition: all 500ms 960ms ease-in-out;
      -ms-transition: all 500ms 960ms ease-in-out;
      -o-transition: all 500ms 960ms ease-in-out;
      transition: all 500ms 960ms ease-in-out; }
    .site-index .firstview.active .firstview__container .firstview__container__ttl span:nth-of-type(17) {
      opacity: 1;
      transform: translateY(0) rotateY(0deg);
      -webkit-transition: all 500ms 1020ms ease-in-out;
      -moz-transition: all 500ms 1020ms ease-in-out;
      -ms-transition: all 500ms 1020ms ease-in-out;
      -o-transition: all 500ms 1020ms ease-in-out;
      transition: all 500ms 1020ms ease-in-out; }
    .site-index .firstview.active .firstview__container .firstview__container__ttl span:nth-of-type(18) {
      opacity: 1;
      transform: translateY(0) rotateY(0deg);
      -webkit-transition: all 500ms 1080ms ease-in-out;
      -moz-transition: all 500ms 1080ms ease-in-out;
      -ms-transition: all 500ms 1080ms ease-in-out;
      -o-transition: all 500ms 1080ms ease-in-out;
      transition: all 500ms 1080ms ease-in-out; }
    .site-index .firstview.active .firstview__container .firstview__container__ttl span:nth-of-type(19) {
      opacity: 1;
      transform: translateY(0) rotateY(0deg);
      -webkit-transition: all 500ms 1140ms ease-in-out;
      -moz-transition: all 500ms 1140ms ease-in-out;
      -ms-transition: all 500ms 1140ms ease-in-out;
      -o-transition: all 500ms 1140ms ease-in-out;
      transition: all 500ms 1140ms ease-in-out; }
    .site-index .firstview.active .firstview__container .firstview__container__ttl span:nth-of-type(20) {
      opacity: 1;
      transform: translateY(0) rotateY(0deg);
      -webkit-transition: all 500ms 1200ms ease-in-out;
      -moz-transition: all 500ms 1200ms ease-in-out;
      -ms-transition: all 500ms 1200ms ease-in-out;
      -o-transition: all 500ms 1200ms ease-in-out;
      transition: all 500ms 1200ms ease-in-out; }
    .site-index .firstview.active .firstview__container .firstview__container__ttl span:nth-of-type(21) {
      opacity: 1;
      transform: translateY(0) rotateY(0deg);
      -webkit-transition: all 500ms 1260ms ease-in-out;
      -moz-transition: all 500ms 1260ms ease-in-out;
      -ms-transition: all 500ms 1260ms ease-in-out;
      -o-transition: all 500ms 1260ms ease-in-out;
      transition: all 500ms 1260ms ease-in-out; }
    .site-index .firstview.active .firstview__container .firstview__container__ttl span:nth-of-type(22) {
      opacity: 1;
      transform: translateY(0) rotateY(0deg);
      -webkit-transition: all 500ms 1320ms ease-in-out;
      -moz-transition: all 500ms 1320ms ease-in-out;
      -ms-transition: all 500ms 1320ms ease-in-out;
      -o-transition: all 500ms 1320ms ease-in-out;
      transition: all 500ms 1320ms ease-in-out; }
    .site-index .firstview.active .firstview__container .firstview__container__ttl span:nth-of-type(23) {
      opacity: 1;
      transform: translateY(0) rotateY(0deg);
      -webkit-transition: all 500ms 1380ms ease-in-out;
      -moz-transition: all 500ms 1380ms ease-in-out;
      -ms-transition: all 500ms 1380ms ease-in-out;
      -o-transition: all 500ms 1380ms ease-in-out;
      transition: all 500ms 1380ms ease-in-out; }
    .site-index .firstview.active .firstview__container .firstview__container__ttl span:nth-of-type(24) {
      opacity: 1;
      transform: translateY(0) rotateY(0deg);
      -webkit-transition: all 500ms 1440ms ease-in-out;
      -moz-transition: all 500ms 1440ms ease-in-out;
      -ms-transition: all 500ms 1440ms ease-in-out;
      -o-transition: all 500ms 1440ms ease-in-out;
      transition: all 500ms 1440ms ease-in-out; }
    .site-index .firstview.active .firstview__container .firstview__container__ttl span:nth-of-type(25) {
      opacity: 1;
      transform: translateY(0) rotateY(0deg);
      -webkit-transition: all 500ms 1500ms ease-in-out;
      -moz-transition: all 500ms 1500ms ease-in-out;
      -ms-transition: all 500ms 1500ms ease-in-out;
      -o-transition: all 500ms 1500ms ease-in-out;
      transition: all 500ms 1500ms ease-in-out; }
    .site-index .firstview.active .firstview__container .firstview__container__ttl span:nth-of-type(26) {
      opacity: 1;
      transform: translateY(0) rotateY(0deg);
      -webkit-transition: all 500ms 1560ms ease-in-out;
      -moz-transition: all 500ms 1560ms ease-in-out;
      -ms-transition: all 500ms 1560ms ease-in-out;
      -o-transition: all 500ms 1560ms ease-in-out;
      transition: all 500ms 1560ms ease-in-out; }
    .site-index .firstview.active .firstview__container .firstview__container__ttl span:nth-of-type(27) {
      opacity: 1;
      transform: translateY(0) rotateY(0deg);
      -webkit-transition: all 500ms 1620ms ease-in-out;
      -moz-transition: all 500ms 1620ms ease-in-out;
      -ms-transition: all 500ms 1620ms ease-in-out;
      -o-transition: all 500ms 1620ms ease-in-out;
      transition: all 500ms 1620ms ease-in-out; }
    .site-index .firstview.active .firstview__container .firstview__container__ttl span:nth-of-type(28) {
      opacity: 1;
      transform: translateY(0) rotateY(0deg);
      -webkit-transition: all 500ms 1680ms ease-in-out;
      -moz-transition: all 500ms 1680ms ease-in-out;
      -ms-transition: all 500ms 1680ms ease-in-out;
      -o-transition: all 500ms 1680ms ease-in-out;
      transition: all 500ms 1680ms ease-in-out; }
    .site-index .firstview.active .firstview__container .firstview__container__ttl span:nth-of-type(29) {
      opacity: 1;
      transform: translateY(0) rotateY(0deg);
      -webkit-transition: all 500ms 1740ms ease-in-out;
      -moz-transition: all 500ms 1740ms ease-in-out;
      -ms-transition: all 500ms 1740ms ease-in-out;
      -o-transition: all 500ms 1740ms ease-in-out;
      transition: all 500ms 1740ms ease-in-out; }
    .site-index .firstview.active .firstview__container .firstview__container__ttl span:nth-of-type(30) {
      opacity: 1;
      transform: translateY(0) rotateY(0deg);
      -webkit-transition: all 500ms 1800ms ease-in-out;
      -moz-transition: all 500ms 1800ms ease-in-out;
      -ms-transition: all 500ms 1800ms ease-in-out;
      -o-transition: all 500ms 1800ms ease-in-out;
      transition: all 500ms 1800ms ease-in-out; }
    .site-index .firstview.active .firstview__container .firstview__container__ttl span:nth-of-type(31) {
      opacity: 1;
      transform: translateY(0) rotateY(0deg);
      -webkit-transition: all 500ms 1860ms ease-in-out;
      -moz-transition: all 500ms 1860ms ease-in-out;
      -ms-transition: all 500ms 1860ms ease-in-out;
      -o-transition: all 500ms 1860ms ease-in-out;
      transition: all 500ms 1860ms ease-in-out; }
    .site-index .firstview.active .firstview__container .firstview__container__ttl span:nth-of-type(32) {
      opacity: 1;
      transform: translateY(0) rotateY(0deg);
      -webkit-transition: all 500ms 1920ms ease-in-out;
      -moz-transition: all 500ms 1920ms ease-in-out;
      -ms-transition: all 500ms 1920ms ease-in-out;
      -o-transition: all 500ms 1920ms ease-in-out;
      transition: all 500ms 1920ms ease-in-out; }
    .site-index .firstview.active .firstview__container .firstview__container__ttl span:nth-of-type(33) {
      opacity: 1;
      transform: translateY(0) rotateY(0deg);
      -webkit-transition: all 500ms 1980ms ease-in-out;
      -moz-transition: all 500ms 1980ms ease-in-out;
      -ms-transition: all 500ms 1980ms ease-in-out;
      -o-transition: all 500ms 1980ms ease-in-out;
      transition: all 500ms 1980ms ease-in-out; }
    .site-index .firstview.active .firstview__container .firstview__container__ttl span:nth-of-type(34) {
      opacity: 1;
      transform: translateY(0) rotateY(0deg);
      -webkit-transition: all 500ms 2040ms ease-in-out;
      -moz-transition: all 500ms 2040ms ease-in-out;
      -ms-transition: all 500ms 2040ms ease-in-out;
      -o-transition: all 500ms 2040ms ease-in-out;
      transition: all 500ms 2040ms ease-in-out; }
    .site-index .firstview.active .firstview__container .firstview__container__ttl span:nth-of-type(35) {
      opacity: 1;
      transform: translateY(0) rotateY(0deg);
      -webkit-transition: all 500ms 2100ms ease-in-out;
      -moz-transition: all 500ms 2100ms ease-in-out;
      -ms-transition: all 500ms 2100ms ease-in-out;
      -o-transition: all 500ms 2100ms ease-in-out;
      transition: all 500ms 2100ms ease-in-out; }
    .site-index .firstview.active .firstview__container .firstview__container__ttl span:nth-of-type(36) {
      opacity: 1;
      transform: translateY(0) rotateY(0deg);
      -webkit-transition: all 500ms 2160ms ease-in-out;
      -moz-transition: all 500ms 2160ms ease-in-out;
      -ms-transition: all 500ms 2160ms ease-in-out;
      -o-transition: all 500ms 2160ms ease-in-out;
      transition: all 500ms 2160ms ease-in-out; }
    .site-index .firstview.active .firstview__container .firstview__container__ttl span:nth-of-type(37) {
      opacity: 1;
      transform: translateY(0) rotateY(0deg);
      -webkit-transition: all 500ms 2220ms ease-in-out;
      -moz-transition: all 500ms 2220ms ease-in-out;
      -ms-transition: all 500ms 2220ms ease-in-out;
      -o-transition: all 500ms 2220ms ease-in-out;
      transition: all 500ms 2220ms ease-in-out; }
    .site-index .firstview.active .firstview__container .firstview__container__ttl span:nth-of-type(38) {
      opacity: 1;
      transform: translateY(0) rotateY(0deg);
      -webkit-transition: all 500ms 2280ms ease-in-out;
      -moz-transition: all 500ms 2280ms ease-in-out;
      -ms-transition: all 500ms 2280ms ease-in-out;
      -o-transition: all 500ms 2280ms ease-in-out;
      transition: all 500ms 2280ms ease-in-out; }
    .site-index .firstview.active .firstview__container .firstview__container__ttl span:nth-of-type(39) {
      opacity: 1;
      transform: translateY(0) rotateY(0deg);
      -webkit-transition: all 500ms 2340ms ease-in-out;
      -moz-transition: all 500ms 2340ms ease-in-out;
      -ms-transition: all 500ms 2340ms ease-in-out;
      -o-transition: all 500ms 2340ms ease-in-out;
      transition: all 500ms 2340ms ease-in-out; }
    .site-index .firstview.active .firstview__container .firstview__container__ttl span:nth-of-type(40) {
      opacity: 1;
      transform: translateY(0) rotateY(0deg);
      -webkit-transition: all 500ms 2400ms ease-in-out;
      -moz-transition: all 500ms 2400ms ease-in-out;
      -ms-transition: all 500ms 2400ms ease-in-out;
      -o-transition: all 500ms 2400ms ease-in-out;
      transition: all 500ms 2400ms ease-in-out; }
    .site-index .firstview.active .firstview__container .firstview__container__ttl span:nth-of-type(41) {
      opacity: 1;
      transform: translateY(0) rotateY(0deg);
      -webkit-transition: all 500ms 2460ms ease-in-out;
      -moz-transition: all 500ms 2460ms ease-in-out;
      -ms-transition: all 500ms 2460ms ease-in-out;
      -o-transition: all 500ms 2460ms ease-in-out;
      transition: all 500ms 2460ms ease-in-out; }
    .site-index .firstview.active .firstview__container .firstview__container__ttl span:nth-of-type(42) {
      opacity: 1;
      transform: translateY(0) rotateY(0deg);
      -webkit-transition: all 500ms 2520ms ease-in-out;
      -moz-transition: all 500ms 2520ms ease-in-out;
      -ms-transition: all 500ms 2520ms ease-in-out;
      -o-transition: all 500ms 2520ms ease-in-out;
      transition: all 500ms 2520ms ease-in-out; }
    .site-index .firstview.active .firstview__container .firstview__container__ttl span:nth-of-type(43) {
      opacity: 1;
      transform: translateY(0) rotateY(0deg);
      -webkit-transition: all 500ms 2580ms ease-in-out;
      -moz-transition: all 500ms 2580ms ease-in-out;
      -ms-transition: all 500ms 2580ms ease-in-out;
      -o-transition: all 500ms 2580ms ease-in-out;
      transition: all 500ms 2580ms ease-in-out; }
    .site-index .firstview.active .firstview__container .firstview__container__ttl span:nth-of-type(44) {
      opacity: 1;
      transform: translateY(0) rotateY(0deg);
      -webkit-transition: all 500ms 2640ms ease-in-out;
      -moz-transition: all 500ms 2640ms ease-in-out;
      -ms-transition: all 500ms 2640ms ease-in-out;
      -o-transition: all 500ms 2640ms ease-in-out;
      transition: all 500ms 2640ms ease-in-out; }
    .site-index .firstview.active .firstview__container .firstview__container__ttl span:nth-of-type(45) {
      opacity: 1;
      transform: translateY(0) rotateY(0deg);
      -webkit-transition: all 500ms 2700ms ease-in-out;
      -moz-transition: all 500ms 2700ms ease-in-out;
      -ms-transition: all 500ms 2700ms ease-in-out;
      -o-transition: all 500ms 2700ms ease-in-out;
      transition: all 500ms 2700ms ease-in-out; }
    .site-index .firstview.active .firstview__container .firstview__container__ttl span:nth-of-type(46) {
      opacity: 1;
      transform: translateY(0) rotateY(0deg);
      -webkit-transition: all 500ms 2760ms ease-in-out;
      -moz-transition: all 500ms 2760ms ease-in-out;
      -ms-transition: all 500ms 2760ms ease-in-out;
      -o-transition: all 500ms 2760ms ease-in-out;
      transition: all 500ms 2760ms ease-in-out; }
    .site-index .firstview.active .firstview__container .firstview__container__ttl span:nth-of-type(47) {
      opacity: 1;
      transform: translateY(0) rotateY(0deg);
      -webkit-transition: all 500ms 2820ms ease-in-out;
      -moz-transition: all 500ms 2820ms ease-in-out;
      -ms-transition: all 500ms 2820ms ease-in-out;
      -o-transition: all 500ms 2820ms ease-in-out;
      transition: all 500ms 2820ms ease-in-out; }
    .site-index .firstview.active .firstview__container .firstview__container__ttl span:nth-of-type(48) {
      opacity: 1;
      transform: translateY(0) rotateY(0deg);
      -webkit-transition: all 500ms 2880ms ease-in-out;
      -moz-transition: all 500ms 2880ms ease-in-out;
      -ms-transition: all 500ms 2880ms ease-in-out;
      -o-transition: all 500ms 2880ms ease-in-out;
      transition: all 500ms 2880ms ease-in-out; }
    .site-index .firstview.active .firstview__container .firstview__container__ttl span:nth-of-type(49) {
      opacity: 1;
      transform: translateY(0) rotateY(0deg);
      -webkit-transition: all 500ms 2940ms ease-in-out;
      -moz-transition: all 500ms 2940ms ease-in-out;
      -ms-transition: all 500ms 2940ms ease-in-out;
      -o-transition: all 500ms 2940ms ease-in-out;
      transition: all 500ms 2940ms ease-in-out; }
    .site-index .firstview.active .firstview__container .firstview__container__ttl span:nth-of-type(50) {
      opacity: 1;
      transform: translateY(0) rotateY(0deg);
      -webkit-transition: all 500ms 3000ms ease-in-out;
      -moz-transition: all 500ms 3000ms ease-in-out;
      -ms-transition: all 500ms 3000ms ease-in-out;
      -o-transition: all 500ms 3000ms ease-in-out;
      transition: all 500ms 3000ms ease-in-out; }
  .site-index .firstview__container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    justify-content: center;
    align-items: center; }
    .site-index .firstview__container__ttl {
      color: #FFF;
      font-size: 72px;
      font-weight: 600;
      text-align: center;
      line-height: 1.4;
      opacity: 0; }
      @media screen and (max-width: 980px) {
        .site-index .firstview__container__ttl {
          font-size: 28px; } }
      .site-index .firstview__container__ttl span {
        display: inline-block;
        opacity: 0;
        transform: translateY(40px) rotateY(-90deg); }
  .site-index .firstview .fvswipe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 800ms; }
    .site-index .firstview .fvswipe.swiper-container-initialized {
      opacity: 1; }
    .site-index .firstview .fvswipe .bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center; }
    .site-index .firstview .fvswipe .swiper-slide {
      position: relative;
      overflow: hidden; }
      .site-index .firstview .fvswipe .swiper-slide video {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        transform: translate(-50%, -50%); }
        @media screen and (max-width: 980px) {
          .site-index .firstview .fvswipe .swiper-slide video {
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            transform: translate(0);
            object-fit: cover;
            object-position: center; } }
    .site-index .firstview .fvswipe .swiper-slide-active .bg,
    .site-index .firstview .fvswipe .swiper-slide-duplicate-active .bg,
    .site-index .firstview .fvswipe .swiper-slide-prev .bg {
      animation: zoomUp 8s linear 0s 1 normal both; }

@keyframes zoomUp {
  0% {
    transform: scale(1.08); }
  100% {
    transform: scale(1); } }

.site-index .information .flex {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between; }
  @media screen and (max-width: 980px) {
    .site-index .information .flex {
      display: block; } }
  .site-index .information .flex .leftside {
    width: 240px; }
    @media screen and (max-width: 980px) {
      .site-index .information .flex .leftside {
        width: 100%;
        display: -webkit-flex;
        display: -moz-flex;
        display: -o-flex;
        display: -ms-flex;
        display: flex;
        -moz-flex-flow: row wrap;
        -webkit-flex-flow: row wrap;
        -o-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-justify-content: space-between;
        -o-justify-content: space-between;
        -ms-justify-content: space-between;
        -moz-justify-content: space-between;
        justify-content: space-between;
        align-items: flex-end; } }
    .site-index .information .flex .leftside h3 {
      font-weight: 700;
      font-size: 28px;
      line-height: 1;
      margin: 0 0 16px; }
      @media screen and (max-width: 980px) {
        .site-index .information .flex .leftside h3 {
          font-size: 20px;
          margin: 0; } }
    .site-index .information .flex .leftside a {
      display: inline-block;
      color: #202020;
      font-weight: 700; }
      .site-index .information .flex .leftside a:hover {
        text-decoration: underline; }
      .site-index .information .flex .leftside a i {
        display: inline-block;
        margin: 0 0 0 12px; }
  .site-index .information .flex .list {
    width: calc(100% - 240px);
    padding: 0 40px;
    height: 200px;
    overflow: auto; }
    @media screen and (max-width: 980px) {
      .site-index .information .flex .list {
        width: 100%;
        height: 172px;
        padding: 15px;
        border: #e8e8e8 1px solid;
        border-radius: 3px;
        margin: 15px 0 0; } }
    .site-index .information .flex .list li {
      list-style: none;
      padding: 0 0 20px;
      margin: 0 0 20px;
      border-bottom: #e8e8e8 1px solid; }
      @media screen and (max-width: 980px) {
        .site-index .information .flex .list li {
          padding: 0 0 15px;
          margin: 0 0 15px; } }
      .site-index .information .flex .list li a {
        display: -webkit-flex;
        display: -moz-flex;
        display: -o-flex;
        display: -ms-flex;
        display: flex;
        -moz-flex-flow: row wrap;
        -webkit-flex-flow: row wrap;
        -o-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-justify-content: space-between;
        -o-justify-content: space-between;
        -ms-justify-content: space-between;
        -moz-justify-content: space-between;
        justify-content: space-between;
        align-items: center;
        color: #202020; }
        @media screen and (max-width: 980px) {
          .site-index .information .flex .list li a {
            justify-content: flex-start; } }
        .site-index .information .flex .list li a:hover h4 {
          text-decoration: underline; }
      .site-index .information .flex .list li time {
        width: 16%;
        font-weight: 600; }
        @media screen and (max-width: 980px) {
          .site-index .information .flex .list li time {
            width: 88px; } }
      .site-index .information .flex .list li .category {
        width: 14%;
        font-size: 12px;
        background: #202020;
        color: #fff;
        padding: 2px 0;
        text-align: center; }
        @media screen and (max-width: 980px) {
          .site-index .information .flex .list li .category {
            width: 80px;
            padding: 0;
            font-size: 11px; } }
      .site-index .information .flex .list li h4 {
        width: 70%;
        font-size: 16px;
        padding: 0 0 0 48px; }
        @media screen and (max-width: 980px) {
          .site-index .information .flex .list li h4 {
            width: 100%;
            padding: 8px 0 0;
            font-size: 14px; } }

.site-index .concept {
  position: relative; }
  .site-index .concept.mb {
    margin: 0 0 80px; }
    @media screen and (max-width: 980px) {
      .site-index .concept.mb {
        margin: 0;
        border-bottom: none !important; } }
  .site-index .concept.wrap {
    padding: 80px 0; }
    @media screen and (max-width: 1119px) {
      .site-index .concept.wrap {
        padding: 64px 0; } }
    @media screen and (max-width: 980px) {
      .site-index .concept.wrap {
        padding: 0;
        padding-bottom: 40px;
        border-bottom: #e8e8e8 1px solid; } }
    .site-index .concept.wrap .display {
      z-index: 2; }
  .site-index .concept__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(50% + 80px);
    height: 100%; }
    .site-index .concept__bg--rvs {
      left: auto;
      right: 0; }
    @media screen and (max-width: 980px) {
      .site-index .concept__bg {
        position: relative;
        width: 100%;
        height: 240px; } }
    .site-index .concept__bg .bg {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-position: center;
      background-size: cover; }
  .site-index .concept__data {
    width: calc(50% - 80px);
    margin: 0 0 0 calc(50% + 80px); }
    @media screen and (max-width: 1119px) {
      .site-index .concept__data {
        padding: 0 20px; } }
    @media screen and (max-width: 980px) {
      .site-index .concept__data {
        width: 100%;
        margin: 24px 0 0;
        padding: 0; } }
    .site-index .concept__data h2 {
      font-size: 122px;
      white-space: nowrap;
      transform: translateX(-47px);
      letter-spacing: .04em;
      line-height: 1;
      color: #ddd;
      font-weight: 600;
      text-shadow: none; }
      @media screen and (max-width: 1119px) {
        .site-index .concept__data h2 {
          font-size: 94px; } }
      @media screen and (max-width: 980px) {
        .site-index .concept__data h2 {
          font-size: 32px;
          transform: translateX(0);
          margin: 15px 0 15px;
          text-align: center; } }
      .site-index .concept__data h2:first-letter {
        color: #00cbb6; }
    .site-index .concept__data dl {
      padding: 24px 0 0 52px; }
      @media screen and (max-width: 980px) {
        .site-index .concept__data dl {
          padding: 0 15px; } }
      .site-index .concept__data dl dt {
        font-size: 22px;
        font-weight: 700;
        letter-spacing: 0;
        line-height: 1.66; }
        @media screen and (max-width: 1119px) {
          .site-index .concept__data dl dt {
            font-size: 18px; } }
        @media screen and (max-width: 980px) {
          .site-index .concept__data dl dt {
            font-size: 16px;
            text-align: center; } }
      .site-index .concept__data dl dd {
        font-size: 15px;
        text-align: justify;
        margin: 20px 0 0; }
        @media screen and (max-width: 1119px) {
          .site-index .concept__data dl dd {
            font-size: 13px; } }
        @media screen and (max-width: 980px) {
          .site-index .concept__data dl dd {
            margin: 12px 0 0; } }
    .site-index .concept__data--rvs {
      margin-left: 0; }
      .site-index .concept__data--rvs h2 {
        transform: translateX(-4px); }
      .site-index .concept__data--rvs dl {
        padding-left: 0;
        padding-right: 52px; }
        @media screen and (max-width: 980px) {
          .site-index .concept__data--rvs dl {
            padding: 0 15px; } }

.site-index .business.wrap {
  padding-left: 8%;
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between; }
  @media screen and (max-width: 980px) {
    .site-index .business.wrap {
      padding-left: 15px;
      padding-right: 15px;
      display: block; } }

.site-index .business .business__txt {
  width: 440px;
  padding: 0 80px 0 0;
  position: relative; }
  @media screen and (max-width: 980px) {
    .site-index .business .business__txt {
      width: auto;
      padding: 0; } }
  .site-index .business .business__txt__h {
    font-size: 71px;
    white-space: nowrap;
    line-height: 1;
    text-indent: -2px;
    letter-spacing: .06em;
    color: #ddd;
    font-weight: 600;
    text-shadow: none; }
    @media screen and (max-width: 980px) {
      .site-index .business .business__txt__h {
        font-size: 32px;
        text-indent: 0;
        text-align: center;
        margin: 0 0 15px; } }
    .site-index .business .business__txt__h:first-letter {
      color: #00cbb6; }
  .site-index .business .business__txt__description dt {
    font-size: 22px;
    font-weight: 700;
    margin: 20px 0 8px;
    position: relative; }
    @media screen and (max-width: 980px) {
      .site-index .business .business__txt__description dt {
        font-size: 17px;
        text-align: center;
        margin: 0 15px 8px; } }
    .site-index .business .business__txt__description dt:before {
      content: '';
      width: 100%;
      height: 1px;
      background: #202020;
      position: absolute;
      top: 50%;
      left: 0;
      z-index: -1; }
      @media screen and (max-width: 980px) {
        .site-index .business .business__txt__description dt:before {
          display: none; } }
    .site-index .business .business__txt__description dt:after {
      content: '';
      width: 44%;
      height: 100%;
      background: #FFF;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1; }
  .site-index .business .business__txt__description dd {
    font-size: 14px;
    text-align: justify; }

.site-index .business .swiper-wrapper {
  transition-timing-function: cubic-bezier(0.83, 0, 0.17, 1); }

.site-index .business .buswipe-pager {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  justify-content: flex-start;
  position: absolute;
  left: 0;
  bottom: 0; }
  @media screen and (max-width: 980px) {
    .site-index .business .buswipe-pager {
      display: none; } }
  .site-index .business .buswipe-pager__item {
    width: 38px;
    height: 38px;
    text-align: center;
    background: #202020;
    border: #202020 1px solid;
    color: #FFF;
    margin: 0 12px 0 0;
    font-size: 12px;
    cursor: pointer;
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: center;
    justify-content: center; }
    .site-index .business .buswipe-pager__item:hover {
      background: #FFF;
      color: #202020; }

.site-index .business .buswipe {
  width: calc(100% - 440px); }
  @media screen and (max-width: 980px) {
    .site-index .business .buswipe {
      width: 100%;
      margin: 24px 0 0; } }
  .site-index .business .buswipe .swiper-slide {
    width: 256px;
    position: relative;
    display: block;
    color: #202020; }
  .site-index .business .buswipe figure {
    border: #e8e8e8 1px solid; }
    .site-index .business .buswipe figure img {
      width: 100%; }
  .site-index .business .buswipe i {
    position: absolute;
    right: 0;
    bottom: 0;
    font-size: 16px; }
    @media screen and (max-width: 980px) {
      .site-index .business .buswipe i {
        font-size: 13px; } }
  .site-index .business .buswipe dl {
    margin: 12px 0 0; }
    .site-index .business .buswipe dl dt {
      font-size: 15px;
      font-weight: 700; }
      @media screen and (max-width: 980px) {
        .site-index .business .buswipe dl dt {
          font-size: 12px; } }
    .site-index .business .buswipe dl dd {
      line-height: 1; }
      @media screen and (max-width: 980px) {
        .site-index .business .buswipe dl dd {
          display: none; } }

.site-index .solution {
  background: #FFF;
  position: relative;
  overflow: hidden; }
  .site-index .solution:before,
  .site-index .solution #particles {
    content: '';
    background: #00cbb6;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 376px;
    z-index: 1; }
  .site-index .solution:after {
    content: '';
    width: 150%;
    left: -25%;
    position: absolute;
    bottom: 0;
    height: 62%;
    background: #fff;
    border-radius: 50%;
    z-index: 1; }
    @media screen and (max-width: 980px) {
      .site-index .solution:after {
        border-radius: 0; } }
  .site-index .solution .display {
    z-index: 2; }
  .site-index .solution__list {
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between; }
    @media screen and (max-width: 980px) {
      .site-index .solution__list {
        display: block; } }
    .site-index .solution__list._active li {
      opacity: 1;
      transform: translateY(0); }
      .site-index .solution__list._active li:nth-child(1) {
        -webkit-transition: all 600ms 200ms ease-in-out;
        -moz-transition: all 600ms 200ms ease-in-out;
        -ms-transition: all 600ms 200ms ease-in-out;
        -o-transition: all 600ms 200ms ease-in-out;
        transition: all 600ms 200ms ease-in-out; }
      .site-index .solution__list._active li:nth-child(2) {
        -webkit-transition: all 600ms 400ms ease-in-out;
        -moz-transition: all 600ms 400ms ease-in-out;
        -ms-transition: all 600ms 400ms ease-in-out;
        -o-transition: all 600ms 400ms ease-in-out;
        transition: all 600ms 400ms ease-in-out; }
      .site-index .solution__list._active li:nth-child(3) {
        -webkit-transition: all 600ms 600ms ease-in-out;
        -moz-transition: all 600ms 600ms ease-in-out;
        -ms-transition: all 600ms 600ms ease-in-out;
        -o-transition: all 600ms 600ms ease-in-out;
        transition: all 600ms 600ms ease-in-out; }
    .site-index .solution__list li {
      width: 31%;
      background: #FFF;
      box-shadow: 0 4px 32px rgba(0, 0, 0, 0.06);
      border-radius: 8px;
      overflow: hidden;
      opacity: 0;
      transform: translateY(40px); }
      @media screen and (max-width: 980px) {
        .site-index .solution__list li {
          width: auto;
          border-radius: 4px;
          margin: 0 0 15px;
          box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08); }
          .site-index .solution__list li:last-child {
            margin: 0; } }
      .site-index .solution__list li figure img {
        width: 100%; }
      .site-index .solution__list li dl {
        padding: 20px; }
        .site-index .solution__list li dl dt {
          text-align: center;
          font-weight: 700;
          font-size: 20px;
          line-height: 1; }
          .site-index .solution__list li dl dt:before {
            content: attr(data-eng);
            display: block;
            font-size: 10px;
            color: #00cbb6;
            margin: 0 0 10px; }
        .site-index .solution__list li dl dd {
          text-align: justify;
          line-height: 1.6;
          margin: 16px 0 0; }

.site-index .product {
  background: #FFF;
  position: relative; }
  .site-index .product:before {
    content: '';
    width: 100%;
    height: 64%;
    position: absolute;
    left: 0;
    bottom: 0;
    background: #f4f5f4; }
  .site-index .product.wrap {
    padding-bottom: 40px; }
  .site-index .product .prswipe {
    padding-bottom: 64px; }
    @media screen and (max-width: 980px) {
      .site-index .product .prswipe {
        padding-bottom: 48px; } }
    .site-index .product .prswipe .swiper-wrapper {
      transition-timing-function: cubic-bezier(0.83, 0, 0.17, 1); }
    .site-index .product .prswipe .swiper-pagination {
      bottom: 0; }
      .site-index .product .prswipe .swiper-pagination .swiper-pagination-bullet {
        margin: 0 6px; }
        .site-index .product .prswipe .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
          transform: scale(1.2);
          background: #00cbb6; }
    .site-index .product .prswipe .swiper-slide {
      width: 280px;
      display: block;
      background: #FFF;
      color: #202020;
      transform: scale(0.84);
      transition: transform 200ms;
      box-shadow: 0 4px 32px rgba(0, 0, 0, 0.06);
      border-radius: 4px;
      overflow: hidden; }
      .site-index .product .prswipe .swiper-slide.swiper-slide-active {
        transform: scale(1); }
      .site-index .product .prswipe .swiper-slide.swiper-slide-next, .site-index .product .prswipe .swiper-slide.swiper-slide-prev {
        transform: scale(0.92); }
      .site-index .product .prswipe .swiper-slide figure img {
        width: 100%; }
      .site-index .product .prswipe .swiper-slide dl {
        padding: 15px; }
        .site-index .product .prswipe .swiper-slide dl dt {
          font-size: 16px;
          font-weight: 700; }
        .site-index .product .prswipe .swiper-slide dl dd.description {
          font-size: 10px; }
        .site-index .product .prswipe .swiper-slide dl dd.home {
          margin: 12px 0 0;
          padding: 12px 0 0;
          border-top: #e8e8e8 1px solid;
          font-size: 12px; }
          .site-index .product .prswipe .swiper-slide dl dd.home i {
            display: inline-block;
            margin: 0 8px 0 0; }

.site-index .videoarea {
  margin: 0 0 104px;
  position: relative; }
  @media screen and (max-width: 980px) {
    .site-index .videoarea {
      margin: 0 0 40px; } }
  .site-index .videoarea:before {
    content: '';
    width: 100%;
    height: 50%;
    position: absolute;
    left: 0;
    bottom: 0;
    background: #f4f5f4; }
  .site-index .videoarea__v {
    width: 800px;
    margin: 0 auto; }
    @media screen and (max-width: 980px) {
      .site-index .videoarea__v {
        width: 100%; } }
    .site-index .videoarea__v iframe {
      width: 100%;
      height: 400px;
      vertical-align: bottom; }
      @media screen and (max-width: 980px) {
        .site-index .videoarea__v iframe {
          height: 48vw; } }

.site-company .philosophy__dl {
  text-align: center;
  font-weight: 700;
  padding: 40px 0;
  border: #f4f5f4 10px solid; }
  @media screen and (max-width: 980px) {
    .site-company .philosophy__dl {
      padding: 24px 20px;
      border-width: 8px; } }
  .site-company .philosophy__dl dt {
    font-size: 24px; }
    @media screen and (max-width: 980px) {
      .site-company .philosophy__dl dt {
        font-size: 20px; } }
    .site-company .philosophy__dl dt:after {
      content: '';
      display: block;
      width: 40px;
      height: 2px;
      background: #202020;
      margin: 16px auto; }
  .site-company .philosophy__dl dd {
    font-size: 15px;
    line-height: 1.8; }
    @media screen and (max-width: 980px) {
      .site-company .philosophy__dl dd {
        font-size: 13px;
        text-align: justify; }
        .site-company .philosophy__dl dd br {
          display: none; } }

.site-company .philosophy__box {
  position: relative;
  margin: 64px 0 0;
  padding: 80px 0; }
  @media screen and (max-width: 1119px) {
    .site-company .philosophy__box {
      margin: 40px 0 0;
      padding: 64px 0; } }
  @media screen and (max-width: 980px) {
    .site-company .philosophy__box {
      margin: 32px 0 0;
      padding: 0; } }
  .site-company .philosophy__box:before {
    content: '';
    background: #f4f5f4;
    width: 80%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0; }
    @media screen and (max-width: 1119px) {
      .site-company .philosophy__box:before {
        right: -15px; } }
    @media screen and (max-width: 980px) {
      .site-company .philosophy__box:before {
        display: none; } }
  .site-company .philosophy__box__bg {
    position: absolute;
    top: 80px;
    left: 0;
    width: 50%;
    height: calc(100% - 160px);
    background-size: cover;
    background-position: center; }
    @media screen and (max-width: 1119px) {
      .site-company .philosophy__box__bg {
        top: 64px;
        left: -15px;
        height: calc(100% - 128px); } }
    @media screen and (max-width: 980px) {
      .site-company .philosophy__box__bg {
        position: relative;
        top: 0;
        left: -15px;
        width: calc(100% + 30px);
        height: 200px;
        margin: 0 0 40px; } }
  .site-company .philosophy__box__txt {
    margin: 0 0 0 50%;
    padding: 80px 0;
    padding-left: 52px; }
    @media screen and (max-width: 1119px) {
      .site-company .philosophy__box__txt {
        padding: 64px 0;
        padding-left: 24px; } }
    @media screen and (max-width: 980px) {
      .site-company .philosophy__box__txt {
        margin: 0;
        padding: 0; } }
    .site-company .philosophy__box__txt dt {
      font-size: 36px;
      font-weight: 700;
      line-height: 1.56; }
      @media screen and (max-width: 1119px) {
        .site-company .philosophy__box__txt dt {
          font-size: 24px; } }
      @media screen and (max-width: 980px) {
        .site-company .philosophy__box__txt dt {
          text-align: center;
          font-size: 20px; } }
      .site-company .philosophy__box__txt dt:before {
        content: attr(data-eng);
        display: block;
        margin: 0 0 8px;
        color: #00cbb6;
        font-weight: 600;
        font-size: 14px;
        text-indent: 2px;
        letter-spacing: .24em; }
    .site-company .philosophy__box__txt dd {
      margin: 20px 0 0;
      font-size: 15px;
      text-align: justify; }
      @media screen and (max-width: 1119px) {
        .site-company .philosophy__box__txt dd {
          font-size: 14px;
          margin-right: 15px; } }
      @media screen and (max-width: 980px) {
        .site-company .philosophy__box__txt dd {
          margin: 15px 0 0;
          font-size: 13.5px; } }

.site-company dl.data.mt {
  margin: 20px 0 0; }

.site-company dl.data dt {
  display: inline-block;
  padding: 2px 0;
  color: #FFF;
  font-size: 14px;
  background: #202020;
  width: 180px;
  text-align: center; }
  @media screen and (max-width: 980px) {
    .site-company dl.data dt {
      width: auto;
      display: block;
      margin: 0 0 8px;
      padding: 3px 0; } }

.site-company dl.data dd {
  margin: 8px 0 0; }

.site-company .map iframe {
  filter: grayscale(1);
  vertical-align: bottom; }
  @media screen and (max-width: 980px) {
    .site-company .map iframe {
      height: 160px; } }

.site-company .license {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  align-items: center;
  justify-content: flex-start; }
  .site-company .license figure {
    width: 400px;
    margin: 0 24px 0 0; }
    @media screen and (max-width: 1119px) {
      .site-company .license figure {
        width: 300px; } }
    @media screen and (max-width: 980px) {
      .site-company .license figure {
        width: auto;
        margin: 0 0 16px; } }
    .site-company .license figure img {
      width: 100%; }

.site-company .aw img {
  max-width: 100%; }

.site-company .agenda {
  border: #f4f5f4 16px solid;
  background: #FFF;
  padding: 20px; }
  @media screen and (max-width: 980px) {
    .site-company .agenda {
      border: none;
      padding: 0; } }
  .site-company .agenda dl {
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 20px;
    padding: 0 0 20px;
    border-bottom: #e8e8e8 1px solid; }
    @media screen and (max-width: 980px) {
      .site-company .agenda dl {
        display: block;
        margin: 0 0 15px;
        padding: 0 0 15px; } }
    .site-company .agenda dl:last-of-type {
      margin: 0;
      padding: 0;
      border: none; }
    .site-company .agenda dl dt {
      background: #00b7a4;
      color: #FFF;
      padding: 4px 0;
      width: 160px;
      text-align: center;
      font-weight: 700; }
      @media screen and (max-width: 980px) {
        .site-company .agenda dl dt {
          width: auto;
          margin: 0 0 8px; } }
    .site-company .agenda dl dd {
      width: calc(100% - 160px);
      font-size: 16px;
      padding: 0 0 0 32px; }
      @media screen and (max-width: 980px) {
        .site-company .agenda dl dd {
          width: auto;
          font-size: 14px;
          padding: 0 15px; } }

.site-recruit .first h2.catch {
  text-align: center;
  font-size: 28px;
  font-weight: 700;
  line-height: 1.5; }
  @media screen and (max-width: 980px) {
    .site-recruit .first h2.catch {
      font-size: 20px;
      letter-spacing: 0em; } }
  .site-recruit .first h2.catch:after {
    content: '';
    width: 40px;
    height: 1px;
    margin: 20px auto;
    display: block;
    background: #202020; }

.site-recruit .first h3.borders {
  background: #f4f5f4;
  text-align: center;
  padding: 15px 2px;
  font-size: 20px;
  font-weight: 700; }
  @media screen and (max-width: 980px) {
    .site-recruit .first h3.borders {
      padding: 12px 2px;
      margin: 0 0 16px;
      border-top: #e8e8e8 4px solid; } }

.site-recruit .first .context {
  text-align: center;
  font-weight: 700;
  font-size: 15px;
  margin: 0 0 32px; }
  @media screen and (max-width: 980px) {
    .site-recruit .first .context {
      margin: 0 15px 24px;
      font-size: 14px;
      text-align: justify; } }

.site-recruit .first .clums {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 64px;
  background: #FFF;
  border: #f4f5f4 20px solid;
  padding: 20px; }
  @media screen and (max-width: 980px) {
    .site-recruit .first .clums {
      padding: 15px;
      border: #eff0ef 4px solid;
      margin: 0 0 32px; } }
  .site-recruit .first .clums__pic {
    width: 400px; }
    @media screen and (max-width: 980px) {
      .site-recruit .first .clums__pic {
        width: auto;
        margin: 0 0 16px;
        padding: 0 0 16px;
        border-bottom: #e8e8e8 1px solid; } }
    .site-recruit .first .clums__pic img {
      width: 100%; }
    .site-recruit .first .clums__pic figcaption {
      font-size: 13px;
      margin: 16px 0 0; }
  .site-recruit .first .clums__text {
    width: calc(100% - 400px);
    padding: 0 0 0 48px; }
    @media screen and (max-width: 980px) {
      .site-recruit .first .clums__text {
        width: auto;
        padding: 0; } }
    .site-recruit .first .clums__text .mb {
      margin: 0 0 20px; }
      @media screen and (max-width: 980px) {
        .site-recruit .first .clums__text .mb {
          margin: 0 0 16px; } }

.site-recruit .first .table-style .mb {
  margin: 0 0 24px; }
  @media screen and (max-width: 980px) {
    .site-recruit .first .table-style .mb {
      margin: 0 0 20px; } }
  .site-recruit .first .table-style .mb.s {
    margin: 0 0 16px; }
    @media screen and (max-width: 980px) {
      .site-recruit .first .table-style .mb.s {
        margin: 0 0 12px; } }

.site-recruit .first .table-style .work dt {
  font-weight: 700;
  font-size: 20px;
  margin: 0 0 4px; }
  @media screen and (max-width: 980px) {
    .site-recruit .first .table-style .work dt {
      font-size: 18px;
      line-height: 1.56; } }

.site-recruit .first .table-style .work dd {
  font-size: 14px; }

.site-recruit .first .table-style .work-small {
  padding: 20px;
  background: #f4f5f4;
  border-radius: 4px; }
  @media screen and (max-width: 980px) {
    .site-recruit .first .table-style .work-small {
      padding: 15px; } }
  .site-recruit .first .table-style .work-small dt {
    font-size: 18px;
    font-weight: 700;
    margin: 0 0 8px;
    padding: 0 0 0 20px;
    position: relative; }
    @media screen and (max-width: 980px) {
      .site-recruit .first .table-style .work-small dt {
        font-size: 16px; } }
    .site-recruit .first .table-style .work-small dt:before {
      content: '';
      width: 12px;
      height: 12px;
      border: #00cbb6 4px solid;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%); }
  .site-recruit .first .table-style .work-small dd {
    font-size: 14px; }
    @media screen and (max-width: 980px) {
      .site-recruit .first .table-style .work-small dd {
        padding: 0 0 12px;
        margin: 0 0 12px;
        border-bottom: #e0e0e0 1px solid; }
        .site-recruit .first .table-style .work-small dd:last-of-type {
          margin: 0;
          padding: 0;
          border: none; } }
  .site-recruit .first .table-style .work-small.square dd:before {
    content: '■';
    margin: 0 8px 0 0;
    display: inline-block;
    opacity: .16; }
    @media screen and (max-width: 980px) {
      .site-recruit .first .table-style .work-small.square dd:before {
        opacity: .2; } }
  .site-recruit .first .table-style .work-small.tryangle dd:before {
    content: '▼';
    margin: 0 8px 0 0;
    display: inline-block;
    opacity: .16; }
    @media screen and (max-width: 980px) {
      .site-recruit .first .table-style .work-small.tryangle dd:before {
        opacity: .2; } }

.site-recruit .first .table-style .step {
  margin: 0 0 20px;
  padding: 0 0 20px;
  border-bottom: #c2c2c2 1px dashed; }
  .site-recruit .first .table-style .step li {
    margin: 0 0 12px; }
    .site-recruit .first .table-style .step li:last-child {
      margin: 0; }
    .site-recruit .first .table-style .step li:nth-child(1):before {
      content: "STEP1";
      border-radius: 999px;
      display: inline-block;
      width: 88px;
      text-align: center;
      margin: 0 12px 0 0;
      background: #00cbb6;
      font-weight: 700;
      color: #FFF; }
    .site-recruit .first .table-style .step li:nth-child(2):before {
      content: "STEP2";
      border-radius: 999px;
      display: inline-block;
      width: 88px;
      text-align: center;
      margin: 0 12px 0 0;
      background: #00cbb6;
      font-weight: 700;
      color: #FFF; }
    .site-recruit .first .table-style .step li:nth-child(3):before {
      content: "STEP3";
      border-radius: 999px;
      display: inline-block;
      width: 88px;
      text-align: center;
      margin: 0 12px 0 0;
      background: #00cbb6;
      font-weight: 700;
      color: #FFF; }

.site-recruit .first .photoarea {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  margin: 40px 0 64px;
  padding: 20px;
  background: #f4f5f4; }
  @media screen and (max-width: 980px) {
    .site-recruit .first .photoarea {
      margin: 20px 0 32px;
      padding: 0;
      background: none; } }
  .site-recruit .first .photoarea figure {
    width: calc(50% - 10px);
    background: #FFF;
    padding: 20px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.05); }
    @media screen and (max-width: 980px) {
      .site-recruit .first .photoarea figure {
        width: auto;
        padding: 0;
        display: block;
        box-shadow: none;
        margin: 0 0 32px; }
        .site-recruit .first .photoarea figure:last-of-type {
          margin: 0; } }
    .site-recruit .first .photoarea figure img {
      width: 200px; }
      @media screen and (max-width: 980px) {
        .site-recruit .first .photoarea figure img {
          width: 100%; } }
    .site-recruit .first .photoarea figure figcaption {
      width: calc(100% - 200px);
      padding: 0 0 0 24px;
      text-align: justify; }
      @media screen and (max-width: 980px) {
        .site-recruit .first .photoarea figure figcaption {
          width: auto;
          padding: 15px 0 0; } }

.site-recruit.reclist .listtitle {
  text-align: center;
  font-weight: 700;
  font-size: 32px;
  line-height: 1.5;
  margin: 0 0 24px; }
  @media screen and (max-width: 980px) {
    .site-recruit.reclist .listtitle {
      font-size: 20px;
      margin: 0 0 16px; } }
  .site-recruit.reclist .listtitle:after {
    content: attr(data-eng);
    font-size: 15px;
    letter-spacing: .12em;
    display: block;
    margin: 0 0 2px; }
    @media screen and (max-width: 980px) {
      .site-recruit.reclist .listtitle:after {
        font-size: 12px; } }

.site-recruit.reclist .listmessage {
  text-align: center;
  font-size: 16px; }
  @media screen and (max-width: 980px) {
    .site-recruit.reclist .listmessage {
      font-size: 13px;
      text-align: justify;
      margin: 0 15px; } }

.site-recruit.reclist .listable {
  background: #f4f5f4;
  padding: 32px;
  margin: 32px 0 0; }
  @media screen and (max-width: 980px) {
    .site-recruit.reclist .listable {
      padding: 15px;
      margin: 24px 0 0;
      border-radius: 3px; } }
  .site-recruit.reclist .listable h3 {
    line-height: 1.5;
    font-size: 32px;
    font-weight: 700;
    margin: 0 0 24px;
    text-align: center; }
    @media screen and (max-width: 980px) {
      .site-recruit.reclist .listable h3 {
        font-size: 20px;
        margin: 0 0 16px; } }
  .site-recruit.reclist .listable__list {
    margin: 0 0 0 -24px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    justify-content: flex-start; }
    @media screen and (max-width: 980px) {
      .site-recruit.reclist .listable__list {
        margin: 0;
        display: block; } }
    .site-recruit.reclist .listable__list__item {
      width: 33.3%;
      margin: 24px 0 0;
      padding: 0 0 0 24px; }
      @media screen and (max-width: 980px) {
        .site-recruit.reclist .listable__list__item {
          width: auto;
          margin: 0 0 15px;
          padding: 0; }
          .site-recruit.reclist .listable__list__item:last-child {
            margin: 0; } }
      .site-recruit.reclist .listable__list__item:nth-child(-n+3) {
        margin-top: 0; }
      .site-recruit.reclist .listable__list__item a {
        display: -webkit-flex;
        display: -moz-flex;
        display: -o-flex;
        display: -ms-flex;
        display: flex;
        -moz-flex-flow: row wrap;
        -webkit-flex-flow: row wrap;
        -o-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-justify-content: space-between;
        -o-justify-content: space-between;
        -ms-justify-content: space-between;
        -moz-justify-content: space-between;
        justify-content: space-between;
        align-items: center;
        color: #202020;
        padding: 15px;
        background: #FFF;
        border-radius: 4px;
        box-shadow: 0 0 16px rgba(0, 0, 0, 0.08);
        position: relative;
        transition: transform 120ms ease-in-out; }
        @media screen and (max-width: 980px) {
          .site-recruit.reclist .listable__list__item a {
            padding: 12px 15px; } }
        .site-recruit.reclist .listable__list__item a:hover {
          transform: scale(1.06); }
          .site-recruit.reclist .listable__list__item a:hover img {
            opacity: 1; }
        .site-recruit.reclist .listable__list__item a:after {
          content: "\f0a9";
          font-weight: 900;
          font-family: "Font Awesome 5 Free";
          position: absolute;
          top: 50%;
          right: 16px;
          transform: translateY(-50%); }
        .site-recruit.reclist .listable__list__item a figure {
          position: relative;
          width: 48px;
          height: 48px;
          overflow: hidden;
          border-radius: 50%; }
          .site-recruit.reclist .listable__list__item a figure img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%; }
        .site-recruit.reclist .listable__list__item a h4 {
          width: calc(100% - 48px);
          padding: 0 0 0 20px;
          font-size: 15px;
          font-weight: 700; }

.site-recruit.reclist .telbtn {
  margin: 48px 0 0;
  text-align: center; }
  @media screen and (max-width: 980px) {
    .site-recruit.reclist .telbtn {
      margin: 24px 0 0; } }
  .site-recruit.reclist .telbtn a {
    border: #202020 2px solid;
    border-radius: 999px;
    padding: 18px 0;
    color: #202020;
    font-size: 18px;
    line-height: 1;
    font-weight: 700;
    width: 480px;
    margin: 0 auto;
    display: block;
    transition: transform 160ms ease-in-out, box-shadow 160ms ease-in-out; }
    @media screen and (max-width: 980px) {
      .site-recruit.reclist .telbtn a {
        width: auto;
        display: block;
        font-size: 16px;
        border-radius: 3px; } }
    .site-recruit.reclist .telbtn a:hover {
      transform: scale(1.04);
      box-shadow: 0 6px 16px rgba(0, 0, 0, 0.18); }
    .site-recruit.reclist .telbtn a .numb {
      display: block;
      margin: 10px 0 0;
      font-weight: 600;
      font-size: 15px; }
    .site-recruit.reclist .telbtn a i {
      display: inline-block;
      margin: 0 8px 0 0; }

.site-recruit.recsingle h2.borders,
.site-recruit.recsingle h3.borders,
.site-recruit.recsingle h4.borders {
  border-bottom: #e8e8e8 1px solid;
  position: relative;
  padding: 12px;
  padding-left: 20px;
  font-size: 22px;
  line-height: 1;
  font-weight: 700;
  margin: 0 0 16px; }
  @media screen and (max-width: 980px) {
    .site-recruit.recsingle h2.borders,
    .site-recruit.recsingle h3.borders,
    .site-recruit.recsingle h4.borders {
      font-size: 18px;
      margin: 0 0 20px;
      padding-left: 18px; } }
  .site-recruit.recsingle h2.borders small,
  .site-recruit.recsingle h3.borders small,
  .site-recruit.recsingle h4.borders small {
    display: inline-block;
    margin: 0 0 0 20px;
    font-size: 13px; }
    @media screen and (max-width: 980px) {
      .site-recruit.recsingle h2.borders small,
      .site-recruit.recsingle h3.borders small,
      .site-recruit.recsingle h4.borders small {
        margin: 0 0 0 15px;
        font-size: 11px; } }
  .site-recruit.recsingle h2.borders:after,
  .site-recruit.recsingle h3.borders:after,
  .site-recruit.recsingle h4.borders:after {
    content: '';
    width: 5px;
    height: 70%;
    background: #202020;
    border-radius: 4px;
    display: block;
    position: absolute;
    top: 15%;
    left: 0; }

.site-recruit.recsingle .photolist {
  margin: 40px 0 80px -20px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between; }
  @media screen and (max-width: 980px) {
    .site-recruit.recsingle .photolist {
      margin: 24px 0;
      display: block; } }
  .site-recruit.recsingle .photolist li {
    width: 50%;
    padding: 0 0 0 20px; }
    @media screen and (max-width: 980px) {
      .site-recruit.recsingle .photolist li {
        width: auto;
        padding: 0;
        margin: 0 0 15px; }
        .site-recruit.recsingle .photolist li:last-child {
          margin: 0; } }
    .site-recruit.recsingle .photolist li figure {
      width: 100%;
      padding-top: 60%;
      position: relative;
      overflow: hidden; }
      .site-recruit.recsingle .photolist li figure img {
        position: absolute;
        width: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }

.site-contact .send {
  margin: 24px 0 0;
  text-align: center; }
  @media screen and (max-width: 980px) {
    .site-contact .send {
      margin: 16px 0 0; } }
  .site-contact .send input {
    background: #00cbb6;
    font-size: 18px;
    font-weight: 700;
    color: #FFF;
    padding: 15px 0;
    width: 320px;
    display: inline-block;
    position: relative;
    -webkit-transition: all 100ms 0ms ease-in-out;
    -moz-transition: all 100ms 0ms ease-in-out;
    -ms-transition: all 100ms 0ms ease-in-out;
    -o-transition: all 100ms 0ms ease-in-out;
    transition: all 100ms 0ms ease-in-out;
    margin: 0 auto;
    border: none;
    cursor: pointer; }
    @media screen and (max-width: 980px) {
      .site-contact .send input {
        width: 100%;
        display: block;
        font-size: 17px; } }
    .site-contact .send input:hover {
      transform: scale(1.1);
      box-shadow: 0 0 16px rgba(0, 203, 182, 0.32); }

.site-product .site-module.wrap.pab {
  margin-bottom: 88px;
  padding-bottom: 88px;
  border-bottom: #e8e8e8 1px solid; }
  @media screen and (max-width: 1119px) {
    .site-product .site-module.wrap.pab {
      margin-bottom: 48px;
      padding-bottom: 48px; } }

.site-product .list {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  justify-content: flex-start;
  margin: 56px 0 56px -40px; }
  @media screen and (max-width: 980px) {
    .site-product .list {
      margin: 24px 0 0 -15px; } }
  .site-product .list__item {
    width: 25%;
    padding: 0 0 0 40px;
    margin: 56px 0 0; }
    @media screen and (max-width: 1119px) {
      .site-product .list__item {
        width: 33.3%; }
        .site-product .list__item:nth-child(-n+3) {
          margin-top: 0; } }
    @media screen and (max-width: 980px) {
      .site-product .list__item {
        width: 50%;
        padding: 0 0 0 15px;
        margin: 0 0 24px; } }
    @media screen and (min-width: 1120px) {
      .site-product .list__item:nth-child(-n+4) {
        margin-top: 0; } }
    .site-product .list__item a {
      display: block;
      height: auto;
      color: #202020; }
    .site-product .list__item figure {
      position: relative;
      width: 100%;
      padding: 100% 0 0;
      overflow: hidden;
      background: #f4f5f4; }
      .site-product .list__item figure img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        object-fit: contain;
        object-position: center; }
    .site-product .list__item h3 {
      text-align: center;
      font-size: 14.5px;
      line-height: 1.56;
      font-weight: 700;
      margin: 12px 0 0; }
      @media screen and (max-width: 980px) {
        .site-product .list__item h3 {
          font-size: 11px; } }

.site-product .clums {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  align-items: center; }
  @media screen and (max-width: 980px) {
    .site-product .clums {
      display: block; } }
  .site-product .clums__pic {
    width: 400px;
    position: relative;
    background: #f4f5f4; }
    @media screen and (max-width: 1119px) {
      .site-product .clums__pic {
        width: 300px; } }
    @media screen and (max-width: 980px) {
      .site-product .clums__pic {
        width: auto; } }
    .site-product .clums__pic img {
      width: 100%;
      height: auto;
      object-fit: contain;
      object-position: center; }
  .site-product .clums__data {
    width: calc(100% - 400px);
    padding: 0 0 0 56px; }
    @media screen and (max-width: 1119px) {
      .site-product .clums__data {
        width: calc(100% - 300px);
        padding: 0 0 0 48px; } }
    @media screen and (max-width: 980px) {
      .site-product .clums__data {
        width: auto;
        padding: 24px 0 0; } }
    .site-product .clums__data__title {
      font-size: 32px;
      font-weight: 700;
      line-height: 1.56;
      margin: 0 0 20px;
      padding: 0 0 12px;
      border-bottom: #e8e8e8 1px solid; }
      @media screen and (max-width: 1119px) {
        .site-product .clums__data__title {
          font-size: 24px;
          margin: 0 0 16px;
          padding: 0 0 10px; } }
      @media screen and (max-width: 980px) {
        .site-product .clums__data__title {
          font-size: 18px; } }
    .site-product .clums__data__desc {
      font-size: 15px;
      text-align: left; }
      @media screen and (max-width: 1119px) {
        .site-product .clums__data__desc {
          font-size: 14px; } }
    .site-product .clums__data .btnarea {
      margin: 24px 0 0;
      text-align: left; }
      @media screen and (max-width: 1119px) {
        .site-product .clums__data .btnarea {
          text-align: center; } }
      .site-product .clums__data .btnarea a {
        margin: 0 8px 8px 0; }

.site-product .sectitle {
  margin: 72px auto 24px;
  text-align: center;
  font-size: 32px;
  font-weight: 700; }
  @media screen and (max-width: 1119px) {
    .site-product .sectitle {
      font-size: 24px; } }
  @media screen and (max-width: 980px) {
    .site-product .sectitle {
      margin: 56px auto 20px; } }

.site-product .question__item {
  background: #f4f5f4;
  margin: 0 0 32px; }
  .site-product .question__item:last-child {
    margin-bottom: 0; }
  .site-product .question__item:nth-child(1) dl dt:before {
    content: "Q.1"; }
  .site-product .question__item:nth-child(2) dl dt:before {
    content: "Q.2"; }
  .site-product .question__item:nth-child(3) dl dt:before {
    content: "Q.3"; }
  .site-product .question__item:nth-child(4) dl dt:before {
    content: "Q.4"; }
  .site-product .question__item:nth-child(5) dl dt:before {
    content: "Q.5"; }
  .site-product .question__item:nth-child(6) dl dt:before {
    content: "Q.6"; }
  .site-product .question__item:nth-child(7) dl dt:before {
    content: "Q.7"; }
  .site-product .question__item:nth-child(8) dl dt:before {
    content: "Q.8"; }
  .site-product .question__item:nth-child(9) dl dt:before {
    content: "Q.9"; }
  .site-product .question__item:nth-child(10) dl dt:before {
    content: "Q.10"; }
  .site-product .question__item:nth-child(11) dl dt:before {
    content: "Q.11"; }
  .site-product .question__item:nth-child(12) dl dt:before {
    content: "Q.12"; }
  .site-product .question__item:nth-child(13) dl dt:before {
    content: "Q.13"; }
  .site-product .question__item:nth-child(14) dl dt:before {
    content: "Q.14"; }
  .site-product .question__item:nth-child(15) dl dt:before {
    content: "Q.15"; }
  .site-product .question__item:nth-child(16) dl dt:before {
    content: "Q.16"; }
  .site-product .question__item:nth-child(17) dl dt:before {
    content: "Q.17"; }
  .site-product .question__item:nth-child(18) dl dt:before {
    content: "Q.18"; }
  .site-product .question__item:nth-child(19) dl dt:before {
    content: "Q.19"; }
  .site-product .question__item:nth-child(20) dl dt:before {
    content: "Q.20"; }
  .site-product .question__item dl {
    padding: 0 24px; }
    @media screen and (max-width: 980px) {
      .site-product .question__item dl {
        padding: 0 15px; } }
    .site-product .question__item dl dt, .site-product .question__item dl dd {
      padding: 24px 0; }
      @media screen and (max-width: 980px) {
        .site-product .question__item dl dt, .site-product .question__item dl dd {
          padding: 15px 0; } }
    .site-product .question__item dl dt {
      font-size: 16px;
      line-height: 1.6;
      font-weight: 700;
      border-bottom: #FFF 4px solid;
      padding-left: 64px;
      position: relative; }
      .site-product .question__item dl dt:before {
        position: absolute;
        top: 22px;
        left: 0;
        font-size: 13px;
        width: 48px;
        font-weight: 500;
        text-align: center;
        padding: 4px 0;
        background: #202020;
        border-radius: 3px;
        color: #FFF; }
    .site-product .question__item dl dd {
      font-size: 15px; }

.site-news .list li {
  list-style: none;
  margin: 0 0 20px;
  padding: 0 0 20px;
  border-bottom: #e8e8e8 1px solid; }
  @media screen and (max-width: 980px) {
    .site-news .list li {
      margin: 0 0 15px;
      padding: 0 0 15px; } }
  .site-news .list li > a {
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: flex-start;
    color: #202020; }
  .site-news .list li:last-child {
    margin: 0;
    padding: 0;
    border: none; }
  .site-news .list li .bg {
    width: 250px;
    height: 140px;
    background-size: cover;
    background-position: center; }
    @media screen and (max-width: 1119px) {
      .site-news .list li .bg {
        width: 200px;
        height: 120px; } }
    @media screen and (max-width: 980px) {
      .site-news .list li .bg {
        width: 100px;
        height: 60px; } }
  .site-news .list li dl {
    width: calc(100% - 250px);
    padding: 0 0 0 24px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: flex-start; }
    @media screen and (max-width: 1119px) {
      .site-news .list li dl {
        width: calc(100% - 200px); } }
    @media screen and (max-width: 980px) {
      .site-news .list li dl {
        width: calc(100% - 100px);
        padding: 0 0 0 20px;
        display: block; } }
    .site-news .list li dl dt {
      font-size: 20px;
      font-weight: 700;
      width: calc(100% - 100px);
      padding: 0 0 0 20px; }
      @media screen and (max-width: 1119px) {
        .site-news .list li dl dt {
          width: calc(100% - 88px);
          font-size: 18px;
          padding: 0 0 0 16px; } }
      @media screen and (max-width: 980px) {
        .site-news .list li dl dt {
          font-size: 16px;
          width: auto;
          padding: 6px 0 0; } }
    .site-news .list li dl dd.time {
      background: #00cbb6;
      color: #FFF;
      font-size: 12px;
      width: 100px;
      padding: 2px 0;
      text-align: center;
      transform: translateY(4px); }
      @media screen and (max-width: 1119px) {
        .site-news .list li dl dd.time {
          width: 88px;
          padding: 1px 0; } }
      @media screen and (max-width: 980px) {
        .site-news .list li dl dd.time {
          transform: translateY(0); } }
    .site-news .list li dl dd.description {
      margin: 8px 0 0;
      font-size: 13.5px;
      text-align: justify; }
      @media screen and (max-width: 1119px) {
        .site-news .list li dl dd.description {
          font-size: 12px; } }
      @media screen and (max-width: 980px) {
        .site-news .list li dl dd.description {
          display: none; } }

.site-news .minlist li {
  margin: 0 0 20px;
  padding: 0 0 20px;
  border-bottom: #e8e8e8 1px solid; }
  @media screen and (max-width: 1119px) {
    .site-news .minlist li {
      margin: 0 0 15px;
      padding: 0 0 15px; } }
  .site-news .minlist li:last-child {
    margin: 0;
    padding: 0;
    border: none; }
  .site-news .minlist li a {
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: flex-start;
    color: #202020; }
    .site-news .minlist li a .bg {
      width: 90px;
      height: 60px;
      background-size: cover;
      background-position: center; }
      @media screen and (max-width: 1119px) {
        .site-news .minlist li a .bg {
          width: 80px;
          height: 55px; } }
    .site-news .minlist li a dl {
      width: calc(100% - 90px);
      padding: 0 0 0 20px; }
      @media screen and (max-width: 1119px) {
        .site-news .minlist li a dl {
          width: calc(100% - 80px);
          padding: 0 0 0 15px; } }
      .site-news .minlist li a dl dt {
        font-size: 14px;
        font-weight: 700;
        line-height: 1.66;
        margin: 8px 0 0; }
      .site-news .minlist li a dl dd.time {
        background: #00cbb6;
        color: #FFF;
        font-size: 11px;
        width: 88px;
        padding: 1px 0;
        text-align: center;
        transform: translateY(4px); }
      .site-news .minlist li a dl dd.description {
        font-size: 13.5px;
        text-align: justify; }

.site-news .details .articletitle {
  margin: 0 0 20px;
  border-bottom: #202020 2px solid;
  padding: 0 0 16px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  align-items: center; }
  @media screen and (max-width: 1119px) {
    .site-news .details .articletitle {
      margin: 0 0 15px;
      padding: 0 0 12px; } }
  @media screen and (max-width: 980px) {
    .site-news .details .articletitle {
      padding: 0 0 8px;
      margin: 0 0 10px; } }
  .site-news .details .articletitle h2 {
    width: calc(100% - 88px);
    padding: 0 0 0 24px;
    line-height: 1.56;
    font-size: 24px;
    font-weight: 700; }
    @media screen and (max-width: 1119px) {
      .site-news .details .articletitle h2 {
        padding: 0 0 0 20px; } }
    @media screen and (max-width: 980px) {
      .site-news .details .articletitle h2 {
        font-size: 18px; } }
  .site-news .details .articletitle time.date {
    color: #FFF;
    background: #00cbb6;
    width: 88px;
    padding: 1px 0;
    text-align: center;
    font-size: 12px;
    display: inline-block; }

.site-news .details__edit {
  font-size: 15px;
  line-height: 1.8; }
  @media screen and (max-width: 1119px) {
    .site-news .details__edit {
      font-size: 14px; } }
  @media screen and (max-width: 980px) {
    .site-news .details__edit {
      font-size: 13.5px; } }
  .site-news .details__edit h3 {
    font-size: 24px;
    font-weight: 700;
    border-top: #202020 2px solid;
    border-bottom: #202020 2px solid;
    font-weight: 700;
    padding: 8px 2px;
    margin: 0 0 20px; }
    @media screen and (max-width: 1119px) {
      .site-news .details__edit h3 {
        font-size: 22px; } }
    @media screen and (max-width: 980px) {
      .site-news .details__edit h3 {
        font-size: 20px; } }
  .site-news .details__edit h4 {
    font-size: 22px;
    border-bottom: #e8e8e8 2px solid;
    font-weight: 700;
    padding: 0 0 0 16px;
    margin: 0 0 16px;
    position: relative; }
    @media screen and (max-width: 1119px) {
      .site-news .details__edit h4 {
        font-size: 20px; } }
    @media screen and (max-width: 980px) {
      .site-news .details__edit h4 {
        font-size: 18px; } }
    .site-news .details__edit h4:before {
      content: '';
      width: 4px;
      height: 80%;
      position: absolute;
      top: 10%;
      left: 0;
      background: #00cbb6;
      border-radius: 2px; }
  .site-news .details__edit h5 {
    font-size: 20px;
    font-weight: 700;
    background: #f4f5f4;
    padding: 4px 15px;
    margin: 0 0 16px; }
    @media screen and (max-width: 1119px) {
      .site-news .details__edit h5 {
        font-size: 18px; } }
    @media screen and (max-width: 980px) {
      .site-news .details__edit h5 {
        font-size: 16px; } }
  .site-news .details__edit h6 {
    position: relative;
    font-size: 18px;
    font-weight: 700;
    padding: 0 0 0 18px;
    margin: 0 0 8px; }
    @media screen and (max-width: 1119px) {
      .site-news .details__edit h6 {
        font-size: 16px; } }
    @media screen and (max-width: 980px) {
      .site-news .details__edit h6 {
        font-size: 15px; } }
    .site-news .details__edit h6:before {
      content: '';
      width: 12px;
      height: 12px;
      border: #00cbb6 4px solid;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%); }
  .site-news .details__edit table {
    border: #e8e8e8 1px solid;
    border-collapse: collapse;
    width: 100%;
    margin: 0 0 40px; }
    @media screen and (max-width: 1119px) {
      .site-news .details__edit table {
        margin: 0 0 32px; } }
    @media screen and (max-width: 980px) {
      .site-news .details__edit table {
        margin: 0 0 24px; } }
    .site-news .details__edit table tbody tr td, .site-news .details__edit table tbody tr th,
    .site-news .details__edit table thead tr td,
    .site-news .details__edit table thead tr th {
      padding: 15px;
      text-align: left;
      vertical-align: middle;
      border: #e8e8e8 1px solid; }
    .site-news .details__edit table tbody tr th,
    .site-news .details__edit table thead tr th {
      background: #f4f5f4; }
  .site-news .details__edit img {
    max-width: 100%; }
  .site-news .details__edit iframe {
    width: 100%;
    height: 300px;
    vertical-align: bottom;
    margin: 0 0 40px; }
    @media screen and (max-width: 1119px) {
      .site-news .details__edit iframe {
        margin: 0 0 32px;
        height: 250px; } }
    @media screen and (max-width: 980px) {
      .site-news .details__edit iframe {
        margin: 0 0 24px;
        height: 210px; } }
  .site-news .details__edit p {
    margin: 0 0 40px; }
    @media screen and (max-width: 1119px) {
      .site-news .details__edit p {
        margin: 0 0 32px; } }
    @media screen and (max-width: 980px) {
      .site-news .details__edit p {
        margin: 0 0 24px; } }

#site-wrapper, #site-footer {
  position: relative;
  top: 78px; }
  @media screen and (max-width: 980px) {
    #site-wrapper, #site-footer {
      top: 60px; } }

.site-clum {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between; }
  @media screen and (max-width: 980px) {
    .site-clum {
      display: block; } }
  .site-clum h2.borders,
  .site-clum h3.borders,
  .site-clum h4.borders {
    border-bottom: #e8e8e8 1px solid;
    position: relative;
    padding: 12px;
    padding-left: 20px;
    font-size: 22px;
    line-height: 1;
    font-weight: 700;
    margin: 0 0 16px; }
    @media screen and (max-width: 980px) {
      .site-clum h2.borders,
      .site-clum h3.borders,
      .site-clum h4.borders {
        font-size: 18px;
        margin: 0 0 20px;
        padding-left: 18px; } }
    .site-clum h2.borders small,
    .site-clum h3.borders small,
    .site-clum h4.borders small {
      display: inline-block;
      margin: 0 0 0 20px;
      font-size: 13px; }
      @media screen and (max-width: 980px) {
        .site-clum h2.borders small,
        .site-clum h3.borders small,
        .site-clum h4.borders small {
          margin: 0 0 0 15px;
          font-size: 11px; } }
    .site-clum h2.borders:after,
    .site-clum h3.borders:after,
    .site-clum h4.borders:after {
      content: '';
      width: 5px;
      height: 70%;
      background: #00cbb6;
      border-radius: 4px;
      display: block;
      position: absolute;
      top: 15%;
      left: 0; }
  .site-clum__sidebar {
    width: 280px; }
    @media screen and (max-width: 1119px) {
      .site-clum__sidebar {
        width: 260px; } }
    @media screen and (max-width: 980px) {
      .site-clum__sidebar {
        width: auto;
        margin: 32px 0 0; } }
  .site-clum__container {
    width: calc(100% - 280px);
    padding: 0 56px 0 0; }
    @media screen and (max-width: 1119px) {
      .site-clum__container {
        width: calc(100% - 260px);
        padding: 0 40px 0 0; } }
    @media screen and (max-width: 980px) {
      .site-clum__container {
        width: auto;
        padding: 0; } }

.pager {
  margin: 24px 0 0;
  text-align: center; }
  .pager a, .pager span {
    width: 28px;
    height: 28px;
    line-height: 26px;
    text-align: center;
    display: inline-block;
    margin: 0 3px;
    border: #e8e8e8 1px solid;
    background: #e8e8e8;
    color: #202020;
    font-size: 13px; }
  .pager span {
    background: #00cbb6;
    border-color: #00cbb6;
    color: #FFF; }
  .pager a:hover {
    color: #00cbb6;
    background: #FFF;
    border-color: #00cbb6; }

.site-module.wrap {
  padding: 104px 0; }
  @media screen and (max-width: 1119px) {
    .site-module.wrap {
      padding: 64px 20px; } }
  @media screen and (max-width: 980px) {
    .site-module.wrap {
      padding: 40px 15px; } }
  .site-module.wrap.mid {
    padding: 80px 0; }
    @media screen and (max-width: 1119px) {
      .site-module.wrap.mid {
        padding: 64px 20px; } }
    @media screen and (max-width: 980px) {
      .site-module.wrap.mid {
        padding: 40px 15px; } }
  .site-module.wrap.no-top {
    padding-top: 0; }
  .site-module.wrap.no-bottom {
    padding-bottom: 0; }

.site-module h2.large,
.site-module h3.large {
  text-align: center;
  color: #202020;
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
  margin: 0 0 40px; }
  @media screen and (max-width: 980px) {
    .site-module h2.large,
    .site-module h3.large {
      font-size: 14px;
      margin: 0 0 24px; } }
  .site-module h2.large.white,
  .site-module h3.large.white {
    color: #FFF; }
  .site-module h2.large:before,
  .site-module h3.large:before {
    content: attr(data-eng);
    font-size: 42px;
    letter-spacing: .04em;
    display: block;
    margin: 0 0 12px; }
    @media screen and (max-width: 980px) {
      .site-module h2.large:before,
      .site-module h3.large:before {
        font-size: 28px; } }

.site-module .btnarea {
  margin: 48px 0 0;
  text-align: center; }
  @media screen and (max-width: 980px) {
    .site-module .btnarea {
      margin: 24px 0 0; } }
  .site-module .btnarea.s {
    margin: 24px 0 0; }
    .site-module .btnarea.s a {
      display: block;
      padding: 8px 0;
      border-radius: 4px; }
  .site-module .btnarea a {
    border-radius: 999px;
    background: #202020;
    color: #FFF;
    border: #202020 1px solid;
    display: inline-block;
    padding: 8px 80px;
    font-size: 17px;
    font-weight: 700; }
    @media screen and (max-width: 980px) {
      .site-module .btnarea a {
        display: block;
        font-size: 16px;
        padding: 8px 0; }
        .site-module .btnarea a:last-of-type {
          margin: 0; } }
    .site-module .btnarea a:hover {
      opacity: .88; }
    .site-module .btnarea a.arrow {
      position: relative; }
      .site-module .btnarea a.arrow:after {
        content: "\f0a9";
        font-weight: 900;
        font-family: "Font Awesome 5 Free";
        position: absolute;
        top: 50%;
        right: 16px;
        font-size: 12px;
        transform: translateY(-50%); }

.site-module .table-style {
  border-collapse: collapse;
  width: 100%; }
  @media screen and (max-width: 980px) {
    .site-module .table-style {
      display: block;
      border: #e8e8e8 1px solid; } }
  @media screen and (max-width: 980px) {
    .site-module .table-style thead,
    .site-module .table-style tbody {
      display: block; } }
  .site-module .table-style thead tr,
  .site-module .table-style tbody tr {
    border-bottom: #e8e8e8 1px solid; }
    @media screen and (max-width: 980px) {
      .site-module .table-style thead tr,
      .site-module .table-style tbody tr {
        display: block; }
        .site-module .table-style thead tr:last-child,
        .site-module .table-style tbody tr:last-child {
          border: none; } }
    .site-module .table-style thead tr:first-child,
    .site-module .table-style tbody tr:first-child {
      border-top: #e8e8e8 1px solid; }
      @media screen and (max-width: 980px) {
        .site-module .table-style thead tr:first-child,
        .site-module .table-style tbody tr:first-child {
          border: none; } }
    .site-module .table-style thead tr th, .site-module .table-style thead tr td,
    .site-module .table-style tbody tr th,
    .site-module .table-style tbody tr td {
      padding: 40px 0;
      font-size: 15px;
      vertical-align: middle;
      text-align: left; }
      @media screen and (max-width: 980px) {
        .site-module .table-style thead tr th, .site-module .table-style thead tr td,
        .site-module .table-style tbody tr th,
        .site-module .table-style tbody tr td {
          display: block;
          font-size: 14px;
          padding: 15px 15px; } }
      .site-module .table-style thead tr th input[type=text],
      .site-module .table-style thead tr th input[type=email],
      .site-module .table-style thead tr th input[type=tel],
      .site-module .table-style thead tr th select,
      .site-module .table-style thead tr th option,
      .site-module .table-style thead tr th textarea, .site-module .table-style thead tr td input[type=text],
      .site-module .table-style thead tr td input[type=email],
      .site-module .table-style thead tr td input[type=tel],
      .site-module .table-style thead tr td select,
      .site-module .table-style thead tr td option,
      .site-module .table-style thead tr td textarea,
      .site-module .table-style tbody tr th input[type=text],
      .site-module .table-style tbody tr th input[type=email],
      .site-module .table-style tbody tr th input[type=tel],
      .site-module .table-style tbody tr th select,
      .site-module .table-style tbody tr th option,
      .site-module .table-style tbody tr th textarea,
      .site-module .table-style tbody tr td input[type=text],
      .site-module .table-style tbody tr td input[type=email],
      .site-module .table-style tbody tr td input[type=tel],
      .site-module .table-style tbody tr td select,
      .site-module .table-style tbody tr td option,
      .site-module .table-style tbody tr td textarea {
        font-family: 'Poppins',"Yu Gothic","游ゴシック",YuGothic,"Hiragino Kaku Gothic Pro","メイリオ", Meiryo,"MS Pゴシック", Helvetica, Arial, Verdana, sans-serif;
        font-weight: 500;
        -webkit-font-feature-settings: "palt";
        font-feature-settings: "palt";
        letter-spacing: 0.024em;
        border: #e8e8e8 1px solid;
        padding: 8px 10px;
        width: 100%;
        font-size: 15px;
        border-radius: 4px;
        overflow: hidden; }
        .site-module .table-style thead tr th input[type=text]:focus,
        .site-module .table-style thead tr th input[type=email]:focus,
        .site-module .table-style thead tr th input[type=tel]:focus,
        .site-module .table-style thead tr th select:focus,
        .site-module .table-style thead tr th option:focus,
        .site-module .table-style thead tr th textarea:focus, .site-module .table-style thead tr td input[type=text]:focus,
        .site-module .table-style thead tr td input[type=email]:focus,
        .site-module .table-style thead tr td input[type=tel]:focus,
        .site-module .table-style thead tr td select:focus,
        .site-module .table-style thead tr td option:focus,
        .site-module .table-style thead tr td textarea:focus,
        .site-module .table-style tbody tr th input[type=text]:focus,
        .site-module .table-style tbody tr th input[type=email]:focus,
        .site-module .table-style tbody tr th input[type=tel]:focus,
        .site-module .table-style tbody tr th select:focus,
        .site-module .table-style tbody tr th option:focus,
        .site-module .table-style tbody tr th textarea:focus,
        .site-module .table-style tbody tr td input[type=text]:focus,
        .site-module .table-style tbody tr td input[type=email]:focus,
        .site-module .table-style tbody tr td input[type=tel]:focus,
        .site-module .table-style tbody tr td select:focus,
        .site-module .table-style tbody tr td option:focus,
        .site-module .table-style tbody tr td textarea:focus {
          box-shadow: inset 1px 2px 2px rgba(0, 0, 0, 0.16); }
      .site-module .table-style thead tr th label, .site-module .table-style thead tr td label,
      .site-module .table-style tbody tr th label,
      .site-module .table-style tbody tr td label {
        display: inline-block;
        margin: 0 20px 0 0; }
        .site-module .table-style thead tr th label input, .site-module .table-style thead tr td label input,
        .site-module .table-style tbody tr th label input,
        .site-module .table-style tbody tr td label input {
          margin: 0 8px 0 0;
          display: inline-block;
          vertical-align: middle;
          transform: translateY(-1px);
          cursor: pointer; }
        .site-module .table-style thead tr th label:last-of-type, .site-module .table-style thead tr td label:last-of-type,
        .site-module .table-style tbody tr th label:last-of-type,
        .site-module .table-style tbody tr td label:last-of-type {
          margin: 0; }
      .site-module .table-style thead tr th select, .site-module .table-style thead tr td select,
      .site-module .table-style tbody tr th select,
      .site-module .table-style tbody tr td select {
        width: auto; }
      .site-module .table-style thead tr th textarea, .site-module .table-style thead tr td textarea,
      .site-module .table-style tbody tr th textarea,
      .site-module .table-style tbody tr td textarea {
        height: 200px; }
    .site-module .table-style thead tr th,
    .site-module .table-style tbody tr th {
      width: 20%;
      font-size: 17px; }
      .site-module .table-style thead tr th.hiss,
      .site-module .table-style tbody tr th.hiss {
        width: 25%;
        padding-right: 40px; }
        @media screen and (max-width: 980px) {
          .site-module .table-style thead tr th.hiss,
          .site-module .table-style tbody tr th.hiss {
            width: auto;
            padding: 15px; } }
        .site-module .table-style thead tr th.hiss:after,
        .site-module .table-style tbody tr th.hiss:after {
          content: '必須';
          float: right;
          background: #00cbb6;
          color: #FFF;
          padding: 2px 12px;
          border-radius: 4px;
          font-weight: 700;
          font-size: 12px; }
      @media screen and (max-width: 980px) {
        .site-module .table-style thead tr th,
        .site-module .table-style tbody tr th {
          width: auto;
          font-size: 15px;
          background: #f4f5f4; } }

.scroll-anime {
  opacity: 0;
  transform: translateY(48px);
  -webkit-transition: all 800ms 0ms ease-in-out;
  -moz-transition: all 800ms 0ms ease-in-out;
  -ms-transition: all 800ms 0ms ease-in-out;
  -o-transition: all 800ms 0ms ease-in-out;
  transition: all 800ms 0ms ease-in-out; }
  .scroll-anime._active {
    opacity: 1;
    transform: translateY(0); }

.scroll-img {
  position: relative; }
  .scroll-img:before, .scroll-img:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 2; }
  .scroll-img:before {
    background: #00cbb6;
    -webkit-transition: all 600ms 600ms cubic-bezier(0.86, 0, 0.07, 1);
    -moz-transition: all 600ms 600ms cubic-bezier(0.86, 0, 0.07, 1);
    -ms-transition: all 600ms 600ms cubic-bezier(0.86, 0, 0.07, 1);
    -o-transition: all 600ms 600ms cubic-bezier(0.86, 0, 0.07, 1);
    transition: all 600ms 600ms cubic-bezier(0.86, 0, 0.07, 1); }
  .scroll-img:after {
    background: #FFF;
    -webkit-transition: all 600ms 0ms cubic-bezier(0.86, 0, 0.07, 1);
    -moz-transition: all 600ms 0ms cubic-bezier(0.86, 0, 0.07, 1);
    -ms-transition: all 600ms 0ms cubic-bezier(0.86, 0, 0.07, 1);
    -o-transition: all 600ms 0ms cubic-bezier(0.86, 0, 0.07, 1);
    transition: all 600ms 0ms cubic-bezier(0.86, 0, 0.07, 1); }
  .scroll-img.__active:before {
    width: 0; }
  .scroll-img.__active:after {
    width: 0; }

.scale-enter-active, .scale-leave-active {
  transition: 600ms; }

.scale-enter, .scale-leave-to {
  opacity: 0; }
