.site-contact{
    .send{
        margin: 24px 0 0;
        text-align: center;
        @media screen and (max-width:$responsive){
            margin: 16px 0 0;
        }
        input{
            background: $green;
            font-size: 18px;
            font-weight: 700;
            color: #FFF;
            padding: 15px 0;
            width: 320px;
            display: inline-block;
            position: relative;
            @include transition(100ms);
            margin: 0 auto;
            border: none;
            cursor: pointer;
            @media screen and (max-width:$responsive){
                width: 100%;
                display: block;
                font-size: 17px;
            }
            &:hover{
                transform: scale(1.1);
                box-shadow: 0 0 16px rgba($green,.32);
            }
        }
    }
}